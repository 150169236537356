import React, { ReactElement, useEffect, useRef, useState } from 'react';
import levenshtein from 'js-levenshtein';
import { nanoid } from 'nanoid';
import { Grid, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Button,
  DiscardButton,
  ImageUploader,
  SaveButton,
  TextInput,
  ToggleButton,
  ToggleButtonOption,
  Typography,
} from '@castiron/components';
import { Shop, shopToEventModel, Asset } from '@castiron/domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { LayoutPageProps } from '../../Layout';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import AdminForm from '../../AdminForm';
import { removeEmpty, useTracking } from '@castiron/utils';
import { upload } from '@castiron/castiron-firebase';
import { useHistory } from 'react-router-dom';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import RichTextInput from '../../RichTextEditor';
import { openModal, closeModal } from '../../../store/reducers/modalConductor';
import { getShopAction, updateShopAction } from '../../../store/reducers/shops';
import { Helmet } from 'react-helmet';
import { getService } from '../../../firebase';
import { shopRepository } from '../../../domain';
import { convertToAsset } from '../../../lib/imageUtils';
import EllipsisMenu from '../../Menus/EllipsisMenu';
import Badges from '../Website/Forms/components/Badges';

const generateDescriptionService = getService('shops', 'generateaboutme', { version: 2 });

interface Props extends LayoutPageProps {}

interface FormValues {
  aboutPageTitle: string;
  aboutPageDescription: string;
  isAboutPageEnabled: boolean;
  generationId: string;
  rawGeneratedDesc: string;
  profileImage: any;
  madeBadge: string;
  certificationsBadges: string[];
  specialDietsBadges: string[];
  moreBadges: string[];
  awardBadge: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& > form': {
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  descriptionField: {
    paddingTop: '16px',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: '12px 12px 0px 0px',
    maxWidth: '100%',
    '& div': {
      border: 'none',
    },
    '& div.ql-toolbar': {
      borderRadius: '0px 0px 12px 12px',
    },
  },
  imageBox: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    display: 'inline-block',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  imagePreview: {
    aspectRatio: '1/1',
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '16px',
    maxWidth: '300px',
    width: 'calc(100% - 32px)',
  },
  menuButton: {
    zIndex: 25,
    backgroundColor: theme.branding.v2.gray[0],
    right: 0,
    margin: 8,
    borderRadius: 12,
    width: 40,
    height: 40,
    position: 'absolute',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    '& svg': {
      color: theme.branding.v2.gray[500],
    },
    '& svg:hover': {
      color: theme.branding.v2.gray[800],
    },
  },
  rightColumn: {
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px',
    },
  },
  statusText: {
    color: theme.branding.v2.gray[700],
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
  },
  toggleButton: {
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
  },
  toggleCaption: {
    fontWeight: 400,
    color: theme.branding.v2.gray[700],
  },
}));

const aboutSchema = () =>
  yup.object().shape({
    isAboutPageEnabled: yup.boolean(),
    aboutPageDescription: yup.string().when('isAboutPageEnabled', {
      is: true,
      then: yup.string().required('Description is required if About page is enabled'),
    }),
    aboutPageTitle: yup.string().when('isAboutPageEnabled', {
      is: true,
      then: yup.string().required('Headline is required if About page is enabled'),
    }),
    generationId: yup.string(),
    rawGeneratedDesc: yup.string(),
    profileImage: yup.object().nullable(),
    madeBadge: yup.string(),
    certificationsBadges: yup.array().of(yup.string()),
    specialDietsBadges: yup.array().of(yup.string()),
    moreBadges: yup.array().of(yup.string()),
    awardBadge: yup.string(),
  });

const AboutPage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formRef = useRef<FormikProps<FormValues>>();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { setFieldValue } = formRef?.current || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [hasGenerated, setHasGenerated] = useState<boolean>(false);
  const [isModalPopped, setIsModalPopped] = useState<boolean>(false);
  const [profileImageObj, setProfileImageObj] = useState(undefined);
  const [photoUploaded, setPhotoUploaded] = useState('');

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    setPageTitle('About');
    setPageIsProFeature(false);
    setBackLocation(true);
    setHeaderCTAs([<ViewShopButton subdirectory="about" />]);

    return () => {
      setBackLocation(false);
      setPageTitle('');
      setPageIsProFeature(false);
    };
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton formikState={formRef.current} isSubmitting={isSubmitting} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting]);

  useEffect(() => {
    if (profileImageObj === undefined && shop?.profileImageObj?.downloadUrl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setProfileImageObj(shop.profileImageObj);
    }
  }, [shop]);

  useEffect(() => {
    if (photoUploaded === 'profile') {
      dispatch(
        openModal({
          modalType: 'EDIT_PHOTO_MODAL',
          modalProps: {
            show: true,
            imageLocation: 'profile',
            imageObj: profileImageObj,
            cropShape: 'round',
            onClose: croppedImage => {
              handleCroppedImage(croppedImage, 'profile');
            },
            onCancel: () => onCropCancel('profile', true),
          },
        }),
      );
    }
  }, [photoUploaded]);

  const handleFile = async (item, assetType) => {
    const { file } = item;
    const id = nanoid();
    //TODO: this was failing when a file did not exist and was not updating products
    //this seemed to fix it (prevents upload) but I'm not sure if that is what should actually be done
    if (file) {
      const metadata = {
        shopId: shop.id,
        originalFilename: id,
        id,
        assetType,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        success: handleFileUploadSuccess,
      };
      const context = {
        shop: shop,
      };
      await upload(file, metadata, options, callbacks, context);
    }
    return id;
  };

  const handleFileUploadSuccess = async (downloadUrl, metadata, options) => {
    console.debug('handleFileUploadSuccess: ', downloadUrl);

    const newProps = removeEmpty({
      [`${metadata.assetType}ImageObj.id`]: metadata.id,
      [`${metadata.assetType}ImageObj.shopId`]: shop.id,
      [`${metadata.assetType}ImageObj.downloadUrl`]: downloadUrl,
      [`${metadata.assetType}ImageObj.metadata`]: metadata,
      [`${metadata.assetType}ImageObj.options`]: options,
    });

    await shopRepository.updateProps(shop.id, newProps);
    dispatch(getShopAction(shop.id));
  };

  const handleCroppedImage = async (croppedImage: File, loc: string) => {
    const metadata = {
      id: profileImageObj?.id,
      shopId: shop.id,
      originalFilename: profileImageObj?.metadata?.originalFilename,
      assetType: loc,
      contentType: profileImageObj?.metadata?.contentType,
    };

    const callbacks = {
      success: handleFileUploadSuccess,
    };

    const options = {
      folder: `user/${shop.id}`,
    };

    const context = {
      shop,
    };

    const croppedImageObj = convertToAsset(
      URL.createObjectURL(croppedImage),
      loc,
      metadata?.originalFilename,
      shop,
      metadata?.contentType,
    );

    await upload(croppedImage, metadata, options, callbacks, context);
    setProfileImageObj(croppedImageObj);
    setFieldValue('profileImage', croppedImageObj);

    dispatch(closeModal());
  };

  const onCropCancel = (loc: string, isNew: boolean) => {
    if (isNew) {
      setProfileImageObj('');
      setFieldValue('profileImage', '');
    } else {
      setProfileImageObj(shop.profileImageObj);
      setFieldValue('profileImage', shop.profileImageObj);
    }

    setPhotoUploaded('');
    dispatch(closeModal());
  };

  const profileMenuOptions = (loc: 'profile') => {
    type option = {
      display: string;
      icon?: React.ReactNode;
      color?: 'error';
      action: () => void;
    };
    let options: option[] = [
      {
        display: 'Edit Photo',
        // icon: <CreateOutlinedIcon />,
        action: () => {
          dispatch(
            openModal({
              modalType: 'EDIT_PHOTO_MODAL',
              modalProps: {
                show: true,
                imageLocation: 'profile',
                imageObj: profileImageObj,
                cropShape: 'round',
                onClose: croppedImage => {
                  handleCroppedImage(croppedImage, loc);
                },
                onCancel: () => onCropCancel(loc, false),
              },
            }),
          );
        },
      },
      {
        display: 'Delete Photo',
        // icon: <DeleteOutlineIcon />,
        color: 'error',
        action: () => {
          setProfileImageObj('');
          setFieldValue('profileImage', '');
          setPhotoUploaded('');
        },
      },
    ];
    return options;
  };

  const initialValues: FormValues = {
    aboutPageDescription: shop.description || '',
    aboutPageTitle: shop.aboutTitle || '',
    isAboutPageEnabled:
      shop?.shopSubpageData?.isAboutPageEnabled === undefined ? true : shop.shopSubpageData.isAboutPageEnabled,
    generationId: '',
    rawGeneratedDesc: '',
    profileImage: shop?.profileImageObj || '',
    madeBadge: shop?.badges?.made || '',
    certificationsBadges: shop?.badges?.certifications || [],
    specialDietsBadges: shop?.badges?.specialDiets || [],
    moreBadges: shop?.badges?.more || [],
    awardBadge: shop?.badges?.award || '',
  };

  const resetForm = (formikProps: FormikProps<FormValues>) => {
    formikProps.setSubmitting(false);
    formikProps.resetForm();
    history.push(`/store/pages`);
  };

  const openAboutModal = () => {
    setIsModalPopped(true);
    dispatch(
      openModal({
        modalType: 'ABOUT_CASTIRON_AI_MODAL',
        modalProps: {
          setIsModalPopped,
          type: 'aboutme',
        },
      }),
    );
  };

  const generateDesc = async () => {
    setShowSpinner(true);
    const setFieldValue = formRef.current.setFieldValue;
    const response = await generateDescriptionService({});
    setHasGenerated(true);
    setFieldValue('generationId', response.generationId);
    setFieldValue('rawGeneratedDesc', response.aboutMe);
    setFieldValue('aboutPageDescription', response.aboutMe);
    setShowSpinner(false);
  };

  const onSubmit = async (values: FormValues, formikProps: FormikProps<FormValues>) => {
    if (values.aboutPageDescription === '<p><br></p>') {
      // This is a hack to get around the fact that the rich text editor is not setting the value to an empty string
      formikProps.setFieldError('aboutPageDescription', 'Description is required if About page is enabled');
      return;
    } else {
      try {
        setIsSubmitting(true);
        const {
          aboutPageDescription,
          aboutPageTitle,
          isAboutPageEnabled,
          profileImage,
          madeBadge,
          certificationsBadges,
          specialDietsBadges,
          moreBadges,
          awardBadge,
        } = values;

        const badges = {
          made: madeBadge || '',
          certifications: certificationsBadges,
          specialDiets: specialDietsBadges,
          more: moreBadges,
          award: awardBadge,
        };

        if (isAboutPageEnabled !== shop.shopSubpageData?.isAboutPageEnabled) {
          trackEvent('Shop Page Visibility Set', {
            page: 'about',
            enabled: isAboutPageEnabled,
            shop: shopToEventModel(shop),
          });
        }

        const newShop = {
          ...shop,
          badges,
          description: aboutPageDescription,
          aboutTitle: aboutPageTitle,
          shopSubpageData: {
            ...shop.shopSubpageData,
            isAboutPageEnabled: isAboutPageEnabled,
          },
        } as Shop;

        if (!profileImage) newShop.profileImageObj = null;

        await dispatch(updateShopAction({ shop: newShop }));

        trackEvent('Shop About Page Updated', {
          shop: shopToEventModel(shop),
        });

        if (values.generationId) {
          const editDistance = levenshtein(values.rawGeneratedDesc, aboutPageDescription);
          trackEvent('AI Generated Content Result', {
            generationId: values.generationId,
            editDistance,
            type: 'shop-description',
          });
        }

        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: (
                <>
                  <Typography variant="h4">About Page Updated!</Typography>
                </>
              ),
            },
          }),
        );

        if (profileImage) {
          if (!shop || profileImage.id !== shop.profileImageObj?.id) {
            await handleFile(profileImage, 'profile');
          }
        }
        setIsSubmitting(false);
        resetForm(formikProps);
        history.push('/store/pages');
      } catch (error) {
        setIsSubmitting(false);
        console.error('Error Submitting Shop About Page Form: ', error);
      }
    }
  };

  const getProfileImageBlock = () => (
    <Grid id="profile-picture" style={!isMobile ? { marginBottom: 24 } : {}}>
      <Grid container direction="row" justify="flex-start">
        <Typography variant="subtitle1">Profile Image</Typography>
        <Tooltip title="We recommend a minimum image size of 512px x 512px." style={{ marginLeft: 5, width: 20 }}>
          <InfoOutlinedIcon />
        </Tooltip>
      </Grid>
      {profileImageObj?.downloadUrl ? (
        <Grid className={classes.imageBox}>
          <Grid container justify="center" className={classes.menuButton}>
            <EllipsisMenu
              options={profileMenuOptions('profile')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          </Grid>
          <img className={classes.imagePreview} src={profileImageObj?.downloadUrl} />
        </Grid>
      ) : (
        <ImageUploader
          onFileDrop={(files): void => {
            const tempAsset = convertToAsset(
              URL.createObjectURL(files[0]),
              'profile',
              files[0].name,
              shop,
              files[0].type,
            );
            setProfileImageObj(tempAsset);
            setFieldValue('profileImage', tempAsset);
            setPhotoUploaded('profile');
          }}
          customColorName='plum'
        />
      )}
    </Grid>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  return (
    <>
      <Grid container justify="center" className={classes.container}>
        <Helmet>
          <title>About | Nourysh</title>
        </Helmet>
        <Formik initialValues={initialValues} validationSchema={aboutSchema} onSubmit={onSubmit} innerRef={formRef}>
          {({ dirty, errors, setFieldValue, touched, values, setFieldTouched, setFieldError }): ReactElement => (
            <AdminForm>
              <Grid
                container
                item
                xs={12}
                direction={isMobile ? 'column-reverse' : 'row'}
                spacing={!isMobile && 6}
                wrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <Grid container item xs={12} md={8} direction="column">
                  <TextInput
                    label={values.isAboutPageEnabled ? 'Headline *' : 'Headline'}
                    name="aboutPageTitle"
                    placeholder="About my site"
                    error={touched.aboutPageTitle && errors.aboutPageTitle}
                  />
                  <Grid container direction="column" item style={{ marginTop: 24 }}>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2">
                        Description {values.isAboutPageEnabled && <span>*</span>}
                      </Typography>
                      <Button
                        loading={showSpinner}
                        disabled={showSpinner}
                        variant="text"
                        onClick={generateDesc}
                        style={{ padding: 0 }}
                      >
                        ✨ {hasGenerated ? 'Try Writing Again' : 'Write with AI'}
                      </Button>
                    </Grid>
                    <RichTextInput
                      name="aboutPageDescription"
                      placeholder="The site description will appear on your site’s About page. This is a place to tell your customers about yourself or your site"
                      className={classes.descriptionField}
                      initialValue={shop.description}
                    />
                    {touched.aboutPageDescription && errors.aboutPageDescription && (
                      <Typography variant="caption" color="error">
                        {errors.aboutPageDescription}
                      </Typography>
                    )}
                  </Grid>
                  <Grid style={{ marginTop: 24 }}>
                    <Badges currentBadges={shop.badges} />
                  </Grid>
                  {isMobile && getProfileImageBlock()}
                </Grid>
                <Grid container item xs={12} md={4} direction="column" className={classes.rightColumn}>
                  {!isMobile && getProfileImageBlock()}
                  <Grid container item direction="column" className={classes.toggleButton}>
                    <Typography variant="subtitle1" className={classes.statusText}>
                      Status
                    </Typography>
                    <ToggleButton
                      value={values.isAboutPageEnabled}
                      exclusive
                      onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
                        setFieldValue('isAboutPageEnabled', value);
                      }}
                      aria-label="page visibility"
                      buttonOptions={toggleButtonOptions}
                    />
                    <Typography variant="caption" className={classes.toggleCaption}>
                      Setting this page to active makes it immediately visible on your website.
                    </Typography>
                  </Grid>
                </Grid>
                {!isSubmitting && !isModalPopped && <UnsavedChangesPrompt when={dirty} />}
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default AboutPage;
