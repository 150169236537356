import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Grid,
  Theme,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ButtonV2, Typography } from "@castiron/components";
import { useAppDispatch } from "../../../hooks";
import { openModal } from "../../../store/reducers/modalConductor";
import {
  TemplateDisplay,
  getActiveCategories,
} from "../Templates/templatesUtils";
import CategoryContainer from "../Templates/CategoryContainer";
import { emailCategoriesMap } from "./emailTemplates";
import MarketingStats from "../MarketingStats";

interface Props {
  activeShopEventTags: string[];
  singleSendEmails: TemplateDisplay[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    gap: "40px",
  },
  sectionHeader: {
    marginBottom: 16,
  },
  templatesContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px 24px",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gap: "16px",
    },
  },
  viewAllButton: {
    padding: 0,
    color: theme.branding.v2.plum[500],
    width: "fit-content",
  },
}));

export const sendFrequencyLabel = (frequency) =>
  frequency === "weekly"
    ? "weekly"
    : frequency === "biweekly"
    ? "biweekly"
    : "monthly";

const Emails: React.FC<Props> = (props: Props) => {
  const { activeShopEventTags, singleSendEmails } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useAppDispatch();

  const [templateCategories, setTemplateCategories] = useState([]);

  useEffect(() => {
    const cats = getActiveCategories(singleSendEmails, emailCategoriesMap);
    const limitedCats = _.slice(cats, 0, isMobile ? 5 : 5);

    setTemplateCategories(limitedCats);
  }, [isMobile, singleSendEmails]);

  const handleOpenTemplatesModal = (scrollTo?: string) => {
    dispatch(
      openModal({
        modalType: "MESSAGING_TEMPLATES_DISPLAY_MODAL",
        modalProps: {
          show: true,
          context: "email",
          templates: singleSendEmails,
          scrollTo,
        },
      })
    );
  };

  return (
    <Grid container className={classes.container}>
      <Grid container item direction="column" style={{ gap: "24px" }}>
        <Typography variant="body1">
          Engage your audience, promote your products and more with email
          marketing.
        </Typography>
        <Grid container item direction="column" style={{ gap: "16px" }}>
          <Grid container item justify="space-between">
            <Typography variant="subtitle1">Email Templates</Typography>
            {!isMobile && (
              <ButtonV2
                variant="text"
                disableRipple
                onClick={() => {
                  handleOpenTemplatesModal();
                }}
                className={classes.viewAllButton}
              >
                View all
              </ButtonV2>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.templatesContainer}>
          {templateCategories.map((category, index) => (
            <React.Fragment key={`email-${index}`}>
              <CategoryContainer
                {...category}
                index={index}
                activeShopEventTags={activeShopEventTags}
                handleOpenTemplatesModal={handleOpenTemplatesModal}
              />
            </React.Fragment>
          ))}
        </Grid>
        {isMobile && (
          <ButtonV2
            variant="text"
            disableRipple
            onClick={() => {
              handleOpenTemplatesModal();
            }}
            className={classes.viewAllButton}
          >
            View all templates
          </ButtonV2>
        )}
      </Grid>
      <MarketingStats context="email" />
    </Grid>
  );
};

export default Emails;
