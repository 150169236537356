import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Dinero from "dinero.js";
import clsx from "clsx";
import _ from "lodash";
import { Formik } from "formik";
import * as yup from "yup";
import moment, { Moment } from "moment";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { getService } from "../../../firebase";
import {
  Grid,
  List,
  ListItem,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
  AddressInput,
  Banner,
  Button,
  CollapsableCard,
  TextInput,
  Typography,
} from "@castiron/components";
import {
  Address,
  addressSchema,
  ChecklistValues,
  Plan,
  PlanDiscount,
  PriceFrequency,
} from "@castiron/domain";
import { Price } from "@castiron/domain";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import AdminForm from "../../AdminForm";
import { closeModal } from "../../../store/reducers/modalConductor";
import PaymentMethodDisplay from "./PaymentMethodDisplay";
import { SelectedSubscription } from "../PlanFlow";
import { PlanContext, PlanContextData } from "../PlanContext";
import { useTracking } from "@castiron/utils";
import {
  getShopAction,
  setDiscountAction,
} from "../../../store/reducers/shops";
import { getSubscriptionStatus } from "../../../lib/accountUtils";
import { useConfig } from "@castiron/castiron-firebase";
import { accountRepository } from "../../../domain";

const getFutureInvoiceService = getService(
  "subscriptions",
  "getfutureinvoicetotals"
);
const createSubscriptionService = getService(
  "subscriptions",
  "createsubscription"
);
const createSetupIntentService = getService(
  "subscriptions",
  "createsetupintent"
);
const changeSubscriptionService = getService(
  "subscriptions",
  "changesubscription"
);
const getBalanceService = getService("stripe", "getbalance");
const sendSubscriptionUpgradeDuringTrialEmail = getService(
  "subscriptions",
  "sendsubupgradeduringtrialemail"
);
const sendSubscriptionDowngradeProrationEmail = getService(
  "subscriptions",
  "sendsubdowngradeprorationemail"
);
const addCouponToSubService = getService("subscriptions", "addcoupontosub");
const validatePromoCodeService = getService(
  "subscriptions",
  "validatepromocode"
);

type Props = {
  nextStep: (curStep: number, plan?: SelectedSubscription) => void;
  selectedSub: SelectedSubscription;
  changePaymentMethod?: boolean;
  setModalHeader?: React.Dispatch<React.SetStateAction<string>>;
  couponCode?: string;
};

type FormValues = {
  address: {
    fullAddress: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    region: string;
    regionName: string;
    country: string;
    postalCode: string;
  };
};

type Validator = (values: FormValues) => boolean;

const useStyles = makeStyles((theme: Theme) => ({
  addressContainer: {
    marginBottom: 24,
    width: "100%",
  },
  addressLine1: {
    "& div": {
      margin: 0,
    },
  },
  addressLineTwoContainer: {
    height: 54,
    maxWidth: 544,
    alignSelf: "center",
  },
  addressLine2CTA: {
    color: theme.branding.v2.plum[500],
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    width: 80,
    marginTop: 24,
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 24px",
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.branding.gray[100],
  },
  cardElement: {
    border: "none",
    padding: 14,
    background: theme.branding.gray[100],
    color: theme.branding.gray[800],
    width: "100%",
    borderRadius: "inherit",
    "& .MuiInputBase-input": {
      ...theme.typography.body4,
      fontSize: 16,
      padding: 0,
      color: theme.branding.gray[800],
    },
    "& ::placeholder": {
      ...theme.typography.body4,
      fontSize: 16,
      opacity: 1,
    },
  },
  changeLink: {
    color: theme.branding.v2.plum[500],
    cursor: "pointer",
  },
  creditCardText: {
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  discountCodeContainer: {
    marginBottom: 24,

    "& svg": {
      color: theme.branding.gray[800],
    },

    "& h6": {
      color: theme.branding.v2.plum[500],
    },
  },
  discountCodeError: {
    color: theme.branding.v2.red[500],
    marginTop: 4,
  },
  discountCodeSuccess: {
    color: theme.branding.v2.green[500],
    marginTop: 4,
  },
  discountCodeTitle: {
    color: theme.branding.gray[800] + " !important",
    marginBottom: 4,
  },
  discountCodeInput: {
    marginRight: 8,
    width: "100%",
  },
  errorBox: {
    backgroundColor: theme.branding.red.light,
    borderRadius: 12,
    padding: "12px 16px",
    margin: "16px 0px",
  },
  errorColor: {
    color: `${theme.branding.red.primary} !important`,
    "& input": {
      color: `${theme.branding.red.primary} !important`,
    },
  },
  errorList: {
    listStyleType: "disc",
  },
  errorListItem: {
    display: "list-item",
    color: theme.branding.red.primary,
    marginLeft: 8,
  },
  finalCost: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    paddingTop: 8,
  },
  leftCardElement: {
    borderBottomLeftRadius: 12,
  },
  lineItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 8,
  },
  loadingIcon: {
    color: theme.branding.gray[100],
  },
  lockIcon: {
    fontSize: 20,
    color: theme.branding.v2.plum[500],
  },
  longCardElement: {
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: "12px 12px 0px 0px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 200,
      width: "100%",
    },
  },
  lowerCardElements: {
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${theme.branding.gray[300]}`,
    borderTop: "none",
    borderRadius: "0 0 12px 12px",
    width: "100%",
  },
  mobileFooter: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: "16px 16px 24px",
    backgroundColor: theme.branding.gray[100],
  },
  moneyIcon: {
    backgroundColor: theme.branding.gray[200],
    padding: "10px 12px 12px",
    borderRadius: 16,
    maxWidth: 48,
    "& svg": {
      color: theme.branding.gray[600],
    },
  },
  orderSummary: {
    width: "100%",
  },
  paymentInfo: {
    backgroundColor: theme.branding.gray[200],
    padding: 24,
    borderRadius: 12,
    marginBottom: 24,
  },
  pendingCanceledBanner: {
    marginTop: 32,
  },
  planInfo: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 16,
    padding: 24,
    marginBottom: 32,
    width: "100%",
  },
  planTitle: {
    color: theme.branding.v2.plum[500],
    marginBottom: 8,
  },
  rightCardElement: {
    height: 44,
    borderBottomRightRadius: 12,
    borderLeft: `1px solid ${theme.branding.gray[300]}`,
  },
  submitError: {
    width: "100%",
    padding: "12px 16px",
    backgroundColor: theme.branding.red.light,
    marginBottom: 16,
    borderRadius: 16,
    "& h3": {
      color: theme.branding.red.dark,
    },
  },
  upperWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "32px 24px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 116px",
    },
  },
}));

const lineItemPriceFormat = "$0,0.00";

const SubscriptionPayment: React.FC<Props> = (props: Props) => {
  const {
    changePaymentMethod,
    nextStep,
    selectedSub,
    setModalHeader,
    couponCode,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();
  const cardRef = useRef<HTMLBaseElement>();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const formikRef = useRef<any>();
  const errorRef = useRef<any>();
  const stripe = useStripe();
  const elements = useElements();

  const { subscription, shop, account, testClock, userState, discount } =
    useAppSelector((state) => ({
      subscription: state.shops.account.subscription,
      shop: state.shops.shop,
      account: state.shops.account,
      testClock: state.debug.stripe?.testClock,
      userState: state.shops.userState,
      discount: state.shops.discount,
    }));

  const ffconfig = useConfig();
  const isFirstMonthPromoEnabled = ffconfig?.featureFlag(
    "feature_first_month_promo",
    shop
  );

  const { step } = useContext<PlanContextData>(PlanContext);

  const isPendingCanceled = subscription?.status === "pending-canceled";
  const useExistingCreditCard =
    subscription?.paymentMethod && isPendingCanceled && !changePaymentMethod;
  const [changeAddress, setChangeAddress] = useState(false);
  const [showaddressLine2Input, setShowaddressLine2Input] = useState(false);
  const [address, setAddress] = useState<Address>();
  const [taxes, setTaxes] = useState(0);
  const [stripeErrors, setStripeErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [lineItems, setLineItems] = useState([]);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [balanceOverageMessage, setBalanceOverageMessage] =
    useState<ReactNode>();
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [stripeTotal, setStripeTotal] = useState<number>(0);
  const [planDiscount, setPlanDiscount] = useState<PlanDiscount>();
  const [total, setTotal] = useState<number>(0);
  const [discountCodeInputText, setDiscountCodeInputText] = useState<string>(
    couponCode || ""
  );
  const [discountCodeOpen, setDiscountCodeOpen] = useState(
    !!couponCode || false
  );
  const [discountCodeError, setDiscountCodeError] = useState("");
  const [discountCodeSuccess, setDiscountCodeSuccess] = useState("");
  const [discountCodeApplied, setDiscountCodeApplied] = useState(false);
  const [couponDiscountLineItem, setCouponDiscountLineItem] = useState({
    left: "",
    right: "",
  });
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const isLegacyNewSubscriber = userState === "legacyNewSubscriber";

  const calculateNextChargeDate = (
    balance: number,
    planPrice: Price,
    renewalDate?: Moment
  ): Moment => {
    const date = renewalDate || moment();
    if (balance < planPrice.amount) {
      return date;
    }
    return calculateNextChargeDate(
      balance - planPrice.amount,
      planPrice,
      date.add(1, planPrice.frequency === "monthly" ? "month" : "year")
    );
  };

  useEffect(() => {
    /* balance is negative when already paid */
    const currentBalanceFormatted = Dinero({
      amount: currentBalance * -1,
    }).toFormat(lineItemPriceFormat);
    if (currentBalance * -1 > selectedSub.price?.amount) {
      const nextChargeDate = calculateNextChargeDate(
        currentBalance,
        selectedSub.price
      ).format("LL");
      setBalanceOverageMessage(
        `Your ${currentBalanceFormatted} in plan credit will be applied to this and future billing cycles until it runs out. Your card won’t be charged again until ${nextChargeDate}.`
      );
    } else {
      setBalanceOverageMessage(
        <>
          We’ve prorated the remainder of your Basic Plan and applied the credit
          of&nbsp;
          <span style={{ fontWeight: 700 }}>{currentBalanceFormatted}</span> to
          your new plan.
        </>
      );
    }
  }, [selectedSub, currentBalance]);

  useEffect(() => {
    if (discount) {
      setPlanDiscount(account?.subscription?.discount);
      setDiscountCodeInputText(discount.code);
      setDiscountCodeApplied(true);
      formikRef?.current?.setFieldValue("discountCode", discount.code);
    }
  }, [discount]);

  useEffect(() => {
    getTotals();
  }, [account, address, selectedSub, discount]);

  useEffect(() => {
    if (
      account &&
      account?.billingAddress &&
      (!!account?.billingAddress?.addressLine1 ||
        !!account?.billingAddress?.fullAddress) &&
      !address
    ) {
      setAddress(account?.billingAddress);
    }
  }, [account, selectedSub]);

  useEffect(() => {
    /* should be able to reuse modal header from previous step if current subscriber */
    if (setModalHeader && userState !== "currentSubscriber") {
      setModalHeader(`${selectedSub.plan.name} Plan`);
    }
  }, [userState]);

  useEffect(() => {
    getBalanceService({}).then((resp) => setCurrentBalance(resp.balance));
  }, []);

  useEffect(() => {
    setTotal(stripeTotal + currentBalance);
  }, [stripeTotal, currentBalance]);

  useEffect(() => {
    if (couponCode) {
      setDiscountCodeInputText(couponCode);
      formikRef?.current?.setFieldValue("discountCode", couponCode);
      applyDiscountCode();
    }
  }, [couponCode]);

  const getTotals = async () => {
    if (selectedSub) {
      const totals = await getFutureInvoiceService({
        address: address,
        planId: selectedSub.plan.id,
        priceId: selectedSub.price.id,
        discountCode:
          discount?.code ||
          account.subscription?.discount?.code ||
          account.subscription?.discount?.integrations.stripe.couponId,
      });
      setTaxes(totals.tax);
      setDiscountAmount(totals.discount);
      setStripeTotal(totals.total);
      setPlanDiscount(totals.planDiscount);
    } else {
      setTaxes(0);
    }
  };

  const planDurationFormat = "M/D/YY";
  const planDuration = useCallback(
    (frequency: PriceFrequency) => {
      const start = ["inTrial", "legacyInTrial"].includes(userState)
        ? moment.unix(account.subscription.trialEndDate)
        : moment();
      const end = moment(start).add(
        1,
        frequency === "yearly" ? "year" : "month"
      );
      return `${start.format(planDurationFormat)}-${end.format(
        planDurationFormat
      )}`;
    },
    [account, userState]
  );

  useEffect(() => {
    const annualFullPrice =
      selectedSub.plan?.prices.find((price) => price.frequency === "monthly")
        .amount * 12;
    const planLineItem = {
      left: `${selectedSub.plan?.name} Plan${
        isPendingCanceled
          ? ""
          : " | " + planDuration(selectedSub.price?.frequency)
      }`,
      right: Dinero({
        amount:
          selectedSub.price?.frequency === "yearly"
            ? annualFullPrice
            : selectedSub.price?.amount,
      }).toFormat(lineItemPriceFormat),
    };

    const taxesLineItem = {
      left: "Taxes",
      right:
        taxes !== null
          ? Dinero({ amount: taxes }).toFormat(lineItemPriceFormat)
          : "$-",
    };

    const annualDiscountLineItem = selectedSub.price?.frequency ===
      "yearly" && {
      left: "Annual Discount",
      right: `- ${Dinero({ amount: selectedSub.price?.discount || 0 }).toFormat(
        lineItemPriceFormat
      )}`,
    };

    const currentBalanceLineItem = currentBalance && {
      left: "Plan Prorate Amount",
      right: Dinero({ amount: currentBalance }).toFormat(lineItemPriceFormat),
    };

    const lineItemsArr = [
      planLineItem,
      annualDiscountLineItem,
      couponDiscountLineItem,
      taxesLineItem,
      currentBalanceLineItem,
    ].filter((x) => !!x);
    setLineItems(lineItemsArr);
  }, [
    account,
    selectedSub,
    isPendingCanceled,
    address,
    taxes,
    currentBalance,
    discount,
    planDiscount,
  ]);

  useEffect(() => {
    if (discount) {
      const itemText = ((discount && !discount.appliesToPlans) ||
        discount?.appliesToPlans.includes(selectedSub.plan.id)) && {
        left:
          discount.type === "percent"
            ? `Coupon - ${discountCodeInputText} ${discount.amount}% off`
            : `Coupon - ${discountCodeInputText} ${Dinero({
                amount: discount.amount || 0,
              }).toFormat(lineItemPriceFormat)} off`,
        right:
          discount.type === "percent"
            ? `- ${Dinero({
                amount:
                  (selectedSub.price?.amount || 0) * (discount.amount / 100),
              }).toFormat(lineItemPriceFormat)}`
            : `- ${Dinero({ amount: discount.amount || 0 }).toFormat(
                lineItemPriceFormat
              )}`,
      };
      setCouponDiscountLineItem(itemText);
      if (!discountCodeOpen) {
        setDiscountCodeOpen(true);
      }
    }
  }, [discount]);

  const finalPriceText = useCallback(() => {
    switch (userState) {
      case "inTrial":
      case "legacyInTrial":
        const daysUntilCharge = moment
          .unix(account.subscription.trialEndDate)
          .diff(moment(), "days");
        return `Due in ${daysUntilCharge} Days`;
      default:
        if (total < 0) {
          return "Remaining Plan Credit";
        } else {
          return `${
            isPendingCanceled
              ? moment.unix(subscription?.nextPaymentDate).format("MMM D")
              : "Today's"
          } Total Charge`;
        }
    }
  }, [account, userState, isPendingCanceled, total]);

  const renderStripeErrors = () => {
    if (!_.isEmpty(stripeErrors)) {
      return (
        <Grid className={classes.errorBox}>
          <Typography variant="body4" className={classes.errorColor}>
            Please address the following error{stripeErrors.length > 1 && "s"}:
          </Typography>
          <List className={classes.errorList}>
            {stripeErrors.map((error) => (
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Typography variant="body4" className={classes.errorListItem}>
                  {error.message}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      );
    }
  };

  const onStripeFieldChange = (e, fieldType) => {
    e.error && setStripeErrors((prev) => [...prev, e.error]);

    if (fieldType === "number") {
      !e.error &&
        setStripeErrors((prev) =>
          prev.filter((prevError) => {
            return !prevError.code.includes("number");
          })
        );
    } else if (fieldType === "expiry") {
      !e.error &&
        setStripeErrors((prev) =>
          prev.filter((prevError) => {
            return !prevError.code.includes("expiry");
          })
        );
    } else {
      !e.error &&
        setStripeErrors((prev) =>
          prev.filter((prevError) => {
            return !prevError.code.includes("cvc");
          })
        );
    }
  };

  const initialValues = {
    address: {
      fullAddress: account?.billingAddress?.fullAddress || "",
      addressLine1: account?.billingAddress?.addressLine1 || "",
      addressLine2: account?.billingAddress?.addressLine2 || "",
      city: account?.billingAddress?.city || "",
      region: account?.billingAddress?.region || "",
      regionName: account?.billingAddress?.regionName || "",
      country: account?.billingAddress?.country || "",
      postalCode: account?.billingAddress?.postalCode || "",
      discountCode: couponCode || "",
    },
  };

  const paymentSchema = yup.object({
    address: addressSchema(false),
  });

  const handleCardElementOnChange = (e) => {
    setCardComplete((cc) => {
      return { ...cc, [e.elementType]: e.complete };
    });
  };

  const validateThen = async (
    values: FormValues,
    validators: Validator[],
    func: (values: FormValues) => Promise<void>
  ) => {
    const valid = _.every(
      validators.map((v) => v(values)),
      (v) => v
    );

    if (valid) {
      return func(values);
    } else {
      setSubmitting(false);
    }
  };

  const requireAddress = (values: FormValues) => {
    if (
      !account?.billingAddress?.postalCode &&
      values.address.fullAddress === ""
    ) {
      setSubmitErrors((prev) => [...prev, "Please enter your address"]);
      return false;
    }

    return true;
  };

  const requireCard = (values: FormValues) => {
    const elementsTouched =
      !!cardComplete.cardNumber &&
      !!cardComplete.cardCvc &&
      !!cardComplete.cardExpiry;

    if (!elementsTouched) {
      setSubmitErrors((prev) => [...prev, "Please enter your card details"]);
      return false;
    }

    return true;
  };

  const createNewSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { clientSecret } = await createSubscriptionService({
      planId: selectedSub.plan.id,
      priceId: selectedSub.price.id,
      testClock,
      discountCode: discount?.code,
      address: {
        addressLine1: values.address.addressLine1,
        addressLine2: values.address.addressLine2,
        city: values.address.city,
        region: values.address.region,
        regionName: values.address.regionName,
        country: values.address.country,
        postalCode: values.address.postalCode,
      },
    });

    if (clientSecret) {
      console.debug("Confirming Card Payment");
      const resp = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      console.debug("Confirmation Response", resp);

      dispatch(setDiscountAction(undefined));

      if (resp.paymentIntent?.status === "succeeded" && !resp.error) {
        // If credit card succeeds add to checklist
        if (
          !shop?.checklistCompletions.includes(ChecklistValues.AddCreditCard)
        ) {
          await shop.addToChecklist(ChecklistValues.AddCreditCard);
          await dispatch(getShopAction(shop.id));
        }

        nextStep(step, selectedSub);
        setSubmitting(false);
      } else {
        setSubmitting(false);
        resp.error && setSubmitErrors((prev) => [...prev, resp.error.message]);
        scrollToError();
      }
    } else {
      dispatch(setDiscountAction(undefined));
      nextStep(step, selectedSub);
      setSubmitting(false);
    }
  };

  const updateSubscription = useCallback(async () => {
    const elementsTouched =
      !!cardComplete.cardNumber &&
      !!cardComplete.cardCvc &&
      !!cardComplete.cardExpiry;

    let clientSecret;
    if (
      selectedSub.plan.id !== account?.subscription?.plan.id ||
      selectedSub.price.id !== account?.subscription?.price.id
    ) {
      const resp = await changeSubscriptionService({
        planId: selectedSub.plan.id,
        priceId: selectedSub.price.id,
        newCard: elementsTouched,
        discountCode: discount?.code,
      });
      clientSecret = resp.clientSecret;
    }

    dispatch(setDiscountAction(undefined));

    return clientSecret;
  }, [account, cardComplete, selectedSub]);

  const updateExistingSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const clientSecret = await updateSubscription();

    if (clientSecret) {
      const resp = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      if (resp.paymentIntent?.status === "succeeded" && !resp.error) {
        if (currentBalance * -1 > selectedSub.price?.amount) {
          sendSubscriptionDowngradeProrationEmail({
            currentBalanceFormatted: Dinero({
              amount: currentBalance * -1,
            }).toFormat(lineItemPriceFormat),
            nextBillingDate: calculateNextChargeDate(
              currentBalance,
              selectedSub.price
            ).format("LL"),
          });
        }
        nextStep(step, selectedSub);

        dispatch(setDiscountAction(undefined));
        setSubmitting(false);
      } else {
        window.scroll(0, 0);
        resp.error && setSubmitErrors([resp.error.message]);
        errorRef.current?.scrollTo({ x: 0, y: 0, behavior: "smooth" });
        setSubmitting(false);
      }
    } else {
      nextStep(step, selectedSub);
      dispatch(setDiscountAction(undefined));
      setSubmitting(false);
    }
  };

  const updateTrialSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    await updateSubscription();
    const resp = await createSetupIntentService({});
    const clientSecret = resp.clientSecret;

    if (clientSecret) {
      const resp = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      if (resp.setupIntent?.status === "succeeded" && !resp.error) {
        if (
          !shop?.checklistCompletions.includes(ChecklistValues.AddCreditCard)
        ) {
          trackEvent("Owner Added Payment Method");
          await shop.addToChecklist(ChecklistValues.AddCreditCard);
          await dispatch(getShopAction(shop.id));
        }
        if (["inTrial", "legacyInTrial"].includes(userState)) {
          sendSubscriptionUpgradeDuringTrialEmail({});
        }
        // current subscriber downgrading & having a balance
        if (
          ["legacyTrialCompleted"].includes(userState) &&
          currentBalance * -1 > selectedSub.price?.amount
        ) {
          sendSubscriptionDowngradeProrationEmail({
            currentBalanceFormatted: Dinero({
              amount: currentBalance * -1,
            }).toFormat(lineItemPriceFormat),
            nextBillingDate: calculateNextChargeDate(
              currentBalance,
              selectedSub.price
            ).format("LL"),
          });
        }

        if (discount) {
          await addCouponToSubService({
            couponId: discount.code,
          });
          dispatch(setDiscountAction(undefined));
        }

        nextStep(step, selectedSub);

        setSubmitting(false);
      } else {
        window.scroll(0, 0);
        resp.error && setSubmitErrors([resp.error.message]);
        errorRef.current?.scrollTo({ x: 0, y: 0, behavior: "smooth" });
        dispatch(setDiscountAction(undefined));
        setSubmitting(false);
      }
    } else {
      nextStep(step, selectedSub);
      dispatch(setDiscountAction(undefined));
      setSubmitting(false);
    }
  };

  const updatePaymentMethod = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { clientSecret } = await createSetupIntentService({});
    const setupIntentResp = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
          address: {
            line1: values.address.addressLine1,
            line2: values.address.addressLine2,
            city: values.address.city,
            state: values.address.region,
            country: values.address.country,
            postal_code: values.address.postalCode,
          },
        },
      },
    });

    if (
      setupIntentResp.setupIntent?.status === "succeeded" &&
      !setupIntentResp.error
    ) {
      nextStep(step, selectedSub);

      setSubmitting(false);
    } else {
      console.error("setupIntent failed", setupIntentResp.error);
      window.scroll(0, 0);
      setupIntentResp.error &&
        setSubmitErrors([...submitErrors, setupIntentResp.error.message]);
      setSubmitting(false);
    }
  };

  const submit = async (values: FormValues) => {
    setSubmitting(true);
    submitErrors && setSubmitErrors([]);

    try {
      if (changePaymentMethod) {
        await validateThen(
          values,
          [requireAddress, requireCard],
          updatePaymentMethod
        );

        trackEvent("Subscription Payment Method Changed", {
          cardAdded: account?.subscription?.paymentMethod ? false : true,
          currentSubscription: account?.subscription,
          newSubscription: selectedSub.plan,
          shopId: shop?.id,
          subscriptionStatus: getSubscriptionStatus(account),
          userState: userState,
        });
      } else {
        switch (userState) {
          case "newUser": // Not needed when Plans page CTAs are implemented
          case "legacyNewSubscriber":
            await createNewSubscription(values);
            if (isFirstMonthPromoEnabled) {
              await accountRepository.updateProps(account.id, {
                "config.showFirstMonthPromo": true,
              });
            }
            break;
          case "currentSubscriber":
            await validateThen(
              values,
              [requireAddress, requireCard],
              updateExistingSubscription
            );
            break;
          case "inTrial":
          case "legacyInTrial":
            await validateThen(
              values,
              [requireAddress, requireCard],
              updateTrialSubscription
            );
            break;
          case "subscriptionEnded":
          case "trialExpired":
          case "legacyTrialCompleted":
            await validateThen(
              values,
              [requireAddress, requireCard],
              createNewSubscription
            );
            break;
          default:
            await validateThen(
              values,
              [requireAddress, requireCard],
              updatePaymentMethod
            );
        }

        trackEvent("Subscription Payment Method Changed", {
          currentSubscription: account?.subscription,
          newSubscription: selectedSub.plan,
          shopId: shop?.id,
          subscriptionStatus: getSubscriptionStatus(account),
          userState: userState,
        });
      }
    } catch (err) {
      console.error("Error processing subscription", err);
      setSubmitErrors((prev) => [
        ...prev,
        `There was an error processing your subscription: ${err.message}`,
      ]);
      setSubmitting(false);
    }
  };

  const scrollToError = () => {
    let errorEl = document.getElementById("error");
    errorEl.scrollIntoView({ block: "nearest" });
    return;
  };

  const onChangeAddress = (setFieldValue) => {
    setChangeAddress(true);
    setAddress({
      fullAddress: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      region: "",
      regionName: "",
      postalCode: "",
      country: "",
    });
    setFieldValue("address", {
      fullAddress: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      region: "",
      regionName: "",
      postalCode: "",
      country: "",
    });
  };

  const onAddressChange = useCallback((addr: Address) => {
    setAddress(addr);
  }, []);

  const getButtonText = () => {
    switch (userState) {
      case "subscriptionEnded":
        return "Reactivate My Plan";
      case "currentSubscriber":
        return "Confirm Plan Change";
      case "legacyNewSubscriber":
        return "Start Free Trial";
      default:
        return "Subscribe";
    }
  };

  let addressText;
  if (account?.billingAddress?.fullAddress) {
    addressText = account.billingAddress.fullAddress;
  } else if (account?.billingAddress?.region) {
    addressText = `${account.billingAddress.city}, ${account.billingAddress.region} ${account.billingAddress.postalCode}`;
  }

  const applyDiscountCode = useCallback(async () => {
    setDiscountCodeError("");
    setDiscountCodeSuccess("");
    try {
      setIsValidatingCode(true);
      const validateCodeResp = await validatePromoCodeService({
        code: discountCodeInputText,
      });

      if (validateCodeResp.couponValid === false) {
        setDiscountCodeError("Not a valid coupon code.");
        dispatch(setDiscountAction(undefined));
      }
      if (
        validateCodeResp.appliesToPlans &&
        !validateCodeResp.appliesToPlans?.includes(selectedSub.plan.id)
      ) {
        setDiscountCodeError(
          "Sorry, this coupon can only be used on a Pro Subscription."
        );
        dispatch(setDiscountAction(undefined));
      }
      if (
        !validateCodeResp.appliesToPlans ||
        validateCodeResp.appliesToPlans.includes(selectedSub.plan.id)
      ) {
        if (!discountCodeApplied && discountCodeInputText) {
          dispatch(setDiscountAction(discountCodeInputText));
          setDiscountCodeApplied(true);
          validateCodeResp.type === "percent"
            ? setDiscountCodeSuccess(
                `${validateCodeResp.amount}% off coupon applied!`
              )
            : setDiscountCodeSuccess(
                `${Dinero({ amount: validateCodeResp.amount }).toFormat(
                  "$0,0"
                )} off coupon applied!`
              );
        } else if (!discountCodeApplied) {
          setDiscountCodeError("No coupon code entered.");
          dispatch(setDiscountAction(undefined));
        }
      }
      setIsValidatingCode(false);
    } catch (err) {
      console.error("Error applying discount code", err);
      if (err.message.startsWith("No such coupon")) {
        setDiscountCodeError("Not a valid coupon code.");
        dispatch(setDiscountAction(undefined));
      } else {
        setDiscountCodeError("Sorry, there was an error applying this coupon.");
        dispatch(setDiscountAction(undefined));
      }
      setIsValidatingCode(false);
    }
  }, [discountCodeInputText, discountCodeApplied]);

  const getNextPaymentDateAfterTrial = () => {
    if (discount) {
      return calculateNextChargeDate(
        discount.type === "percent"
          ? currentBalance +
              (selectedSub.price?.amount || 0) * (discount.amount / 100)
          : currentBalance + discount.amount,
        selectedSub.price,
        moment.unix(account?.subscription?.trialEndDate)
      ).format("LL");
    }
    return moment.unix(account?.subscription?.trialEndDate).format("LL");
  };

  return (
    <Grid
      container
      wrap={isMobile ? "wrap" : "nowrap"}
      alignItems="center"
      direction="column"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={paymentSchema}
        validateOnMount
        innerRef={formikRef}
      >
        {({ errors, touched, setFieldValue }): ReactElement => {
          return (
            <AdminForm>
              <Grid container item className={classes.upperWrapper}>
                <div style={{ width: "100%" }} id="error">
                  {!_.isEmpty(submitErrors) && submitErrors.length > 1 ? (
                    <Grid className={classes.errorBox} style={{ marginTop: 0 }}>
                      <Typography variant="h4" className={classes.errorColor}>
                        Error:
                      </Typography>
                      <List
                        className={classes.errorList}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        {submitErrors.map((error) => (
                          <ListItem>
                            <Typography
                              variant="body4"
                              className={classes.errorListItem}
                            >
                              {error}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  ) : submitErrors.length === 1 ? (
                    <Grid className={classes.errorBox} style={{ marginTop: 0 }}>
                      <Typography variant="h4" className={classes.errorColor}>
                        Error:{" "}
                        <span style={{ fontWeight: 400 }}>
                          {submitErrors[0]}
                        </span>
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </div>
                {!changePaymentMethod && !isLegacyNewSubscriber && (
                  <Grid
                    container
                    item
                    className={classes.planInfo}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={9}>
                      <Typography
                        variant="subtitle1"
                        className={classes.planTitle}
                      >
                        {selectedSub.plan?.name}{" "}
                        {selectedSub.price?.frequency === "yearly"
                          ? "Annual"
                          : "Monthly"}{" "}
                        Plan
                      </Typography>
                      <Typography variant="body1">
                        {selectedSub.price?.frequency === "yearly"
                          ? "One Time"
                          : "Monthly"}{" "}
                        Payment of{" "}
                        {selectedSub.price &&
                          Dinero({
                            amount:
                              selectedSub.price.amount -
                              (selectedSub.price?.discount || 0),
                          }).toFormat("$0,0")}
                        {selectedSub.takeRate > 0
                          ? `+ ${selectedSub.takeRate}% / order`
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.moneyIcon} xs={3}>
                      <AttachMoneyIcon />
                    </Grid>
                  </Grid>
                )}
                {isLegacyNewSubscriber && (
                  <Grid
                    container
                    item
                    className={classes.planInfo}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={9}>
                      <Typography
                        variant="subtitle1"
                        className={classes.planTitle}
                      >
                        {selectedSub.plan?.name}{" "}
                        {selectedSub.price?.frequency === "yearly"
                          ? "Annual"
                          : "Monthly"}{" "}
                        Trial
                      </Typography>
                      <Typography variant="body1">14-Day Free Trial</Typography>
                    </Grid>
                    <Grid item className={classes.moneyIcon} xs={3}>
                      <AttachMoneyIcon />
                    </Grid>
                  </Grid>
                )}
                <div id="address" style={{ width: "100%" }}>
                  <Grid container item className={classes.addressContainer}>
                    <Typography variant="button">Billing Address</Typography>
                    {((!!addressText && !changeAddress && !address) ||
                      isPendingCanceled) &&
                    !changePaymentMethod ? (
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="flex-start"
                        justify="center"
                      >
                        <Typography>
                          {addressText}{" "}
                          {!isPendingCanceled && (
                            <span
                              className={classes.changeLink}
                              onClick={() => onChangeAddress(setFieldValue)}
                            >
                              Change
                            </span>
                          )}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="flex-start"
                        justify="center"
                        style={{ width: "100%" }}
                      >
                        <Grid
                          container
                          className={classes.addressLine1}
                          id="address"
                        >
                          <AddressInput
                            required
                            addressFields={{
                              address: "address.fullAddress",
                              addressLine1: "address.addressLine1",
                              city: "address.city",
                              region: "address.region",
                              regionName: "address.regionName",
                              postalCode: "address.postalCode",
                              country: "address.country",
                            }}
                            onAddressChange={onAddressChange}
                            error={
                              touched.address?.fullAddress &&
                              errors.address?.fullAddress
                            }
                          />
                        </Grid>
                        <Grid item style={{ width: "100%", marginTop: 8 }}>
                          {showaddressLine2Input ? (
                            <Grid>
                              <Typography
                                variant="button"
                                style={{ marginTop: 8 }}
                              >
                                Apt #, Suite, Floor
                              </Typography>
                              <TextInput
                                name="address.addressLine2"
                                className={classes.addressLineTwoContainer}
                                placeholder="Apt #, Suite, Floor"
                              />
                            </Grid>
                          ) : (
                            <Typography
                              variant="button"
                              className={classes.addressLine2CTA}
                              onClick={() => setShowaddressLine2Input(true)}
                            >
                              + Add Apt #, Suite, Floor
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <div id="discountCode" style={{ width: "100%" }}>
                  <CollapsableCard
                    title="+ Add a Coupon Code"
                    expanded={discountCodeOpen}
                    handleExpand={() => setDiscountCodeOpen(!discountCodeOpen)}
                    className={classes.discountCodeContainer}
                    noScroll
                  >
                    <Grid
                      container
                      item
                      direction="column"
                      alignItems="flex-start"
                      style={{ marginBottom: 16 }}
                    >
                      <Typography
                        variant="subtitle2"
                        className={classes.discountCodeTitle}
                      >
                        Coupon Code
                      </Typography>
                      <Grid
                        container
                        item
                        justify="space-between"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid item className={classes.discountCodeInput}>
                          <TextInput
                            name="discountCode"
                            onChange={(e) => {
                              setDiscountCodeInputText(e.target.value);
                              setFieldValue("discountCode", e.target.value);
                            }}
                          />
                        </Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={applyDiscountCode}
                          loading={isValidatingCode}
                        >
                          Apply
                        </Button>
                      </Grid>
                      <Grid
                        container
                        item
                        justify="flex-start"
                        alignItems="center"
                      >
                        {discountCodeError && (
                          <Typography
                            variant="body1"
                            className={classes.discountCodeError}
                          >
                            {discountCodeError}
                          </Typography>
                        )}
                        {discountCodeSuccess && (
                          <Typography
                            variant="body1"
                            className={classes.discountCodeSuccess}
                          >
                            {discountCodeSuccess}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </CollapsableCard>
                </div>
                {useExistingCreditCard ? (
                  <Grid
                    container
                    item
                    direction="column"
                    style={{ marginBottom: 32 }}
                  >
                    <Typography variant="button">Credit/Debit Card</Typography>
                    <Grid item style={{ padding: 12 }}>
                      <PaymentMethodDisplay
                        brand={subscription?.paymentMethod?.brand}
                        last4={subscription?.paymentMethod?.last4}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {!isLegacyNewSubscriber && (
                      <Grid
                        container
                        item
                        className={classes.paymentInfo}
                        direction="column"
                      >
                        <Grid
                          container
                          item
                          justify="space-between"
                          direction="column"
                        >
                          <Grid item className={classes.creditCardText}>
                            <Typography variant="button">
                              Credit/Debit Card Info
                            </Typography>
                            <LockOutlinedIcon className={classes.lockIcon} />
                          </Grid>
                          <Grid item>
                            <span ref={cardRef}>
                              <CardNumberElement
                                onChange={(e) => {
                                  onStripeFieldChange(e, "number");
                                  handleCardElementOnChange(e);
                                }}
                                options={{
                                  classes: {
                                    base: clsx([
                                      classes.cardElement,
                                      classes.longCardElement,
                                    ]),
                                  },
                                  placeholder: "Card Number",
                                  style: {
                                    // for whatever reason you can only edit the placeholder inline
                                    base: {
                                      fontSize: "16px",
                                      "::placeholder": {
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        lineHeight: "24px",
                                        color: theme.branding.gray[700],
                                      },
                                    },
                                  },
                                }}
                              />
                              <Grid
                                container
                                item
                                className={classes.lowerCardElements}
                              >
                                <Grid item xs={6}>
                                  <CardExpiryElement
                                    onChange={(e) => {
                                      onStripeFieldChange(e, "expiry");
                                      handleCardElementOnChange(e);
                                    }}
                                    options={{
                                      classes: {
                                        base: clsx([
                                          classes.leftCardElement,
                                          classes.cardElement,
                                        ]),
                                      },
                                      style: {
                                        base: {
                                          fontSize: "16px",
                                          "::placeholder": {
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            color: theme.branding.gray[700],
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <CardCvcElement
                                    onChange={(e) => {
                                      onStripeFieldChange(e, "cvc");
                                      handleCardElementOnChange(e);
                                    }}
                                    options={{
                                      classes: {
                                        base: clsx([
                                          classes.rightCardElement,
                                          classes.cardElement,
                                        ]),
                                      },
                                      style: {
                                        base: {
                                          fontSize: "16px",
                                          "::placeholder": {
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            color: theme.branding.gray[700],
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </span>
                            {renderStripeErrors()}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {!!currentBalance && (
                  <Grid item style={{ marginBottom: "32px" }}>
                    <Banner variant="money">
                      <Typography variant="body2" style={{ color: "inherit" }}>
                        {balanceOverageMessage}
                      </Typography>
                    </Banner>
                  </Grid>
                )}
                {!changePaymentMethod && !isLegacyNewSubscriber && (
                  <Grid container item className={classes.orderSummary}>
                    {lineItems.map((lineItem) => (
                      <Grid container item className={classes.lineItem}>
                        <Grid item xs={9}>
                          <Typography variant="body1">
                            {lineItem.left}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {lineItem.right}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item style={{ width: "100%" }}>
                      <Grid
                        container
                        className={clsx(classes.lineItem, classes.finalCost)}
                      >
                        {["inTrial", "legacyInTrial"].includes(userState) && (
                          <>
                            <Grid item xs={9}>
                              <Typography variant="h4">Due Today</Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: "right" }}>
                              <Typography variant="body1">
                                {Dinero({ amount: 0 }).toFormat(
                                  lineItemPriceFormat
                                )}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={9}>
                          <Typography variant="h4">
                            {finalPriceText()}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {Dinero({ amount: total }).toFormat(
                              lineItemPriceFormat
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {isLegacyNewSubscriber && (
                  <Grid container item className={classes.orderSummary}>
                    <Grid container item className={classes.lineItem}>
                      <Grid item xs={9}>
                        <Typography variant="body1">
                          {selectedSub.plan.name} Trial
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: "right" }}>
                        <Typography variant="body1">$0.00</Typography>
                      </Grid>
                    </Grid>
                    <Grid container item className={classes.lineItem}>
                      <Grid item xs={9}>
                        <Typography variant="body1">Taxes</Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: "right" }}>
                        <Typography variant="body1">$0.00</Typography>
                      </Grid>
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <Grid
                        container
                        className={clsx(classes.lineItem, classes.finalCost)}
                      >
                        <Grid item xs={9}>
                          <Typography variant="h4">
                            Today's Total Charge
                          </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          <Typography variant="body1">$0.00</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {isPendingCanceled && !changePaymentMethod && (
                  <Banner
                    variant="info-yellow"
                    className={classes.pendingCanceledBanner}
                  >
                    <Typography variant="body2">
                      Your plan change will happen immediately but your card
                      will not be charged until the <b>next billing cycle</b>{" "}
                      scheduled for{" "}
                      {moment
                        .unix(subscription?.nextPaymentDate)
                        .format("MMMM D, YYYY")}
                      .
                    </Typography>
                  </Banner>
                )}
                {userState === "inTrial" && (
                  <Banner variant="money">
                    <Typography variant="body2" style={{ color: "inherit" }}>
                      Your card will be charged on{" "}
                      <span style={{ fontWeight: 700 }}>
                        {getNextPaymentDateAfterTrial()}
                      </span>
                      &nbsp;at the end of your free trial. We’ll send you a
                      reminder a few days before your card is charged. You can
                      cancel at any time.
                    </Typography>
                  </Banner>
                )}
                {userState === "legacyInTrial" && (
                  <Banner variant="info-yellow">
                    <Typography variant="body2">
                      <span style={{ fontWeight: 700 }}>Please note:</span> Your
                      card will be charged on&nbsp;
                      <span>{getNextPaymentDateAfterTrial()}</span> at the end
                      of your free trial. Once charged, you will not be able to
                      return to a Starter Plan should you choose to downgrade
                      your account.
                    </Typography>
                  </Banner>
                )}
              </Grid>
              {isMobile ? (
                <Grid className={classes.mobileFooter}>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => dispatch(closeModal())}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.button}
                    type="submit"
                    loading={submitting}
                    loadingClassName={classes.loadingIcon}
                    disabled={submitting || stripeErrors.length > 0}
                  >
                    {submitting ? "" : getButtonText()}
                  </Button>
                </Grid>
              ) : (
                <Grid container item className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting || stripeErrors.length > 0}
                    style={{ padding: "16px 26px" }}
                    loading={submitting}
                    loadingClassName={classes.loadingIcon}
                  >
                    {submitting ? "" : getButtonText()}
                  </Button>
                </Grid>
              )}
            </AdminForm>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default SubscriptionPayment;
