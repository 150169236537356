import React from "react";
import _ from "lodash";
import {
  ButtonBase,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import { Typography } from "@castiron/components";

export type Props = {
  activeShopEventTags: string[];
  icon: string;
  iconBackground?: string;
  name: string;
  description: string;
  index: number;
  eventTag?: string;
  handleOpenTemplatesModal: (scrollTo?: string) => void;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  categoryContainer: {
    height: "100%",
    padding: "16px",
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: "12px",
    textAlign: "left",
    gap: 16,
  },
  categoryIconContainer: {
    height: 72,
    minWidth: 72,
    maxWidth: 72,
    borderRadius: "12px",
    backgroundColor: theme.branding.v2.gray[100],
  },
}));

const CategoryContainer: React.FC<Props> = (props: Props) => {
  const {
    activeShopEventTags,
    icon,
    iconBackground,
    name,
    description,
    eventTag,
    index,
    handleOpenTemplatesModal,
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  if (eventTag && !activeShopEventTags?.includes(eventTag)) return null;
  return (
    <ButtonBase
      key={`single-send-email-category-${index}`}
      onClick={() => {
        handleOpenTemplatesModal(eventTag || name);
      }}
    >
      <Grid
        container
        item
        className={classes.categoryContainer}
        wrap="nowrap"
        alignContent="flex-start"
      >
        <Grid
          container
          item
          justify="center"
          alignContent="center"
          className={classes.categoryIconContainer}
          style={iconBackground ? { backgroundColor: iconBackground } : {}}
        >
          <Typography variant="h4" style={{ fontSize: "32px" }}>
            {icon}
          </Typography>
        </Grid>
        <Grid container item direction="column" wrap="nowrap" justify="center">
          <Typography variant="subtitle1">{name} Templates</Typography>
          <Typography
            variant="body2"
            style={{ color: theme.branding.v2.gray[500] }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default CategoryContainer;
