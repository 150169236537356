import React, { useEffect, useRef } from "react";

import { useFormikContext } from "formik";
import {
  Button,
  fade,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import {
  SvgIcon,
  Text,
  Typography,
  HouseBadge,
  WomanBadge,
  FoodEstablishmentBadge,
  SinkBadge,
  ShieldBadge,
  SilverwareBadge,
  GlutenBadge,
  PlantBadge,
  FriendlyBadge,
  HeartBadge,
  AwardBadge,
} from "@castiron/components";
import { FeatureFlag } from "@castiron/castiron-firebase";

const useStyles = makeStyles((theme: Theme) => ({
  awardContainer: {
    // TODO: Replace with new theme color
    backgroundColor: "#F5F9FF",
    padding: 16,
    borderRadius: 16,
  },
  outterContainer: {
    marginBottom: "16px",
  },
  container: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[700]}3D`,
    padding: 24,
  },
  label: {
    marginBottom: 8,
  },
  badgesContainer: {
    gap: 8,
  },
  badge: {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    border: `1px solid ${theme.branding.gray[700]}3D`,
    borderRadius: "28px",
  },
  selected: {
    border: `1px solid ${theme.branding.v2.plum[500]}`,
    backgroundColor: fade(theme.branding.v2.plum[500], 0.1),
  },
  sectionContainer: {
    marginBottom: 24,
  },
  icon: {
    marginRight: 4,
  },
}));

interface Props {
  focus?: boolean;
  currentBadges?: {
    certifications?: string[];
    made?: string;
    more?: string[];
    specialDiets?: string[];
    award?: string;
  };
}

const Badges = (props: Props) => {
  const { focus, currentBadges } = props;
  const { setFieldValue, values }: any = useFormikContext();

  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (focus) ref.current?.scrollIntoView();
  }, [focus]);

  const classes = useStyles();
  const theme = useTheme();

  const badgeSections = [
    {
      type: "more",
      label: "Share more about your business",
      badges: [
        {
          value: "minority-owned",
          svg: <HeartBadge />,
          label: "Minority-Owned Business",
          onClick: () =>
            setFieldValue(
              "moreBadges",
              values.moreBadges.includes("minority-owned")
                ? values.moreBadges.filter((b) => b !== "minority-owned")
                : [...values.moreBadges, "minority-owned"]
            ),
          selected: values.moreBadges.includes("minority-owned"),
        },
        {
          value: "woman-owned",
          svg: <WomanBadge />,
          label: "Woman-Owned Business",
          onClick: () =>
            setFieldValue(
              "moreBadges",
              values.moreBadges.includes("woman-owned")
                ? values.moreBadges.filter((b) => b !== "woman-owned")
                : [...values.moreBadges, "woman-owned"]
            ),
          selected: values.moreBadges.includes("woman-owned"),
        },
      ],
    },
  ];

  const awardFeatureFlagWrapper = (children) => {
    return (
      <FeatureFlag name="feature_2022_food_entrepreneur">
        {children}
      </FeatureFlag>
    );
  };

  return (
    <Grid item container direction="column" className={classes.outterContainer}>
      <Typography variant="subtitle1">
        <span ref={ref}>Shop Badges</span>
      </Typography>
      <Typography variant="body4">
        Let your customers know more about your business. All badges selected
        are visible in your storefront.
      </Typography>
      <Grid className={classes.container}>
        {badgeSections.map((badgeSection, index) => {
          const isAward = badgeSection.type === "awards";
          const badge = (
            <Grid
              key={`${badgeSection.type}-${index}`}
              id={`${badgeSection.type}`}
              className={
                isAward ? classes.awardContainer : classes.sectionContainer
              }
            >
              <Typography variant="subtitle2" className={classes.label}>
                {badgeSection.label}
              </Typography>
              <Grid container className={classes.badgesContainer}>
                {badgeSection.badges.map((badge) => (
                  <Button
                    onClick={badge.onClick}
                    className={`${classes.badge} ${
                      badge.selected ? classes.selected : ""
                    }`}
                    key={badge.label}
                  >
                    <SvgIcon className={classes.icon}>{badge.svg}</SvgIcon>
                    <Typography variant="body2">{badge.label}</Typography>
                  </Button>
                ))}
              </Grid>
            </Grid>
          );
          return isAward ? awardFeatureFlagWrapper(badge) : badge;
        })}
      </Grid>
    </Grid>
  );
};

export default Badges;
