import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { DialogContent, Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { BusinessAddressInput, Button, Typography } from '@castiron/components';
import { Address, Shop, addressSchema } from '@castiron/domain';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import ModalWrapper from '../RootModal/ModalWrapper';
import { openModal } from '../../store/reducers/modalConductor';
import { getShopAction, updateShopAction } from '../../store/reducers/shops';
import ModalHeader from '../RootModal/ModalHeader';
import ModalActions from '../RootModal/ModalActions';

export interface Props {
  context?: 'quote' | 'fulfillment';
  onSuccess?: (address: Address) => void;
  show: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  textContainer: {
    textAlign: 'center',
    margin: '8px 0px',
  },
}));

const SeoLocationModal: React.FC<Props> = (props: Props) => {
  const { context, onSuccess, show } = props;

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme()
;
  const isQuoteOrFulfillment = context === 'quote' || context === 'fulfillment';

  const handleClose = (): void => {
    localStorage.setItem('seenSeoLocationModal', 'true');
    dispatch(closeModal());
  };

  const [submitting, setSubmitting] = useState(false);
  const formikRef = useRef() as any;

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const seoLocationSchema = yup.object().shape({
    address: addressSchema(true),
  });

  const shopAddress = shop?.physicalAddress;

  const initialValues = {
    address: {
      fullAddress: shopAddress?.fullAddress || '',
      addressLine1: shopAddress?.addressLine1 || '',
      addressLine2: shopAddress?.addressLine2 || '',
      city: shopAddress?.city || '',
      region: shopAddress?.region || '',
      regionName: shopAddress?.regionName || '',
      postalCode: shopAddress?.postalCode || '',
      country: shopAddress?.country || '',
    },
  };

  const onSubmit = async (values, formikProps) => {
    setSubmitting(true);
    try {
      const seoMetadataManuallyUpdated =
      values.address.city !== shop?.seoMetadata?.address?.addressLocality ||
      values.address.region !== shop?.seoMetadata?.address?.addressRegion;

      const newShop: Shop = {
        ...shop,
        physicalAddress: { ...values.address },
        seoMetadata: {
          ...shop?.seoMetadata,
          ...(seoMetadataManuallyUpdated && { manuallyUpdated: true }),
          address: {
            addressCountry: values.address.country,
            addressLocality: values.address.city,
            addressRegion: values.address.region,
            postalCode: values.address.postalCode,
          },
        },
      };

      const newCompletions = [];

      await dispatch(updateShopAction({ shop: newShop, newCompletions })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(
            openModal({
              modalType: 'SIMPLE_ALERT',
              modalProps: {
                show: true,
                celebrate: true,
                content: 'Changes have been saved!',
              },
            }),
          );
          onSuccess && onSuccess(values.address);
        }
      });

      await dispatch(getShopAction(shop?.id));

      setSubmitting(false);
      formikProps.resetForm();
    } catch (err) {
      setSubmitting(false);
      console.error('Error Submitting Shop Form: ', err);
    }
  };

  return (
    <ModalWrapper size="xs" show={show}>
      <Formik
        initialValues={initialValues}
        validationSchema={seoLocationSchema}
        onSubmit={onSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <>
            <ModalHeader title={`${isQuoteOrFulfillment ? 'Add' : 'Business'} Address`} handleClose={handleClose} />
            <DialogContent style={{ padding: 24 }}>
              <Grid item xs={12} className={classes.textContainer}>
                <Typography variant="h2" style={{ fontSize: 40, height: 60 }}>
                  📍
                </Typography>
              </Grid>
              {isQuoteOrFulfillment ? (
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                  <Typography variant="body1">
                    Easily re-use your primary business address when building quotes and local pickup fulfillment
                    options.
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.textContainer}>
                  <Typography variant="h2">Add Your Business Address</Typography>
                </Grid>
              )}
              <Grid container item xs={12}>
                <BusinessAddressInput removeBusinessInfo={isQuoteOrFulfillment} customColor={theme.branding.v2.plum[500]} />
              </Grid>
            </DialogContent>
            <ModalActions>
              <Grid container item xs={12} spacing={1} justify="flex-end">
                <Grid item>
                  <Button variant="outlined" onClick={handleClose}>
                    {isQuoteOrFulfillment ? 'Cancel' : 'Not Now'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button disabled={submitting} variant="contained" type="submit" onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </ModalActions>
          </>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default SeoLocationModal;
