import { Banner, Typography } from "@castiron/components";
import { Special } from "@castiron/domain";
import { Grid, Theme, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import SinglePopup from "./SinglePopup";
import { getProductsAction } from "../../../store/reducers/products";

interface Props {
  activeShopEvents: Special[];
}

export interface PopupContent {
  isEvent?: boolean;
  tag: string;
  name?: string;
  slug?: string;
  icon?: string;
  popup: {
    title: string;
    description: string;
    imageUrl: string;
  };
  eventDefaults?: {
    headline: string;
    description: string;
  };
}

export const popupContent = {
  subscribe: {
    tag: "subscribe",
    popup: {
      title: "Subscribe Popup",
      description: "Encourage visitors to become members of your community.",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/castiron-prod.appspot.com/o/nourysh%2Fpopups%2Fsign%20up.png?alt=media&token=fe08f75b-e39c-402b-a0cd-13d289d86db5",
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  popupsContainer: {
    gap: "16px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(315px, 1fr))",
    },
  },
}));

const Popups: React.FC<Props> = (props: Props) => {
  const { activeShopEvents } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { products, shop } = useAppSelector((state) => ({
    products: state.products.products,
    shop: state.shops.shop,
  }));

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop) getProducts();
  }, [shop]);

  return (
    <Grid container item style={{ gap: "40px" }}>
      <Grid container item direction="column" style={{ gap: "16px" }}>
        <Typography variant="body1">
          A collection of popup templates to prompt your customers to interact
          with your shop in several ways.
        </Typography>
        <Banner variant="info-white">
          <Typography variant="body2">
            Popups only appear once per visit on the home page.
          </Typography>
        </Banner>
      </Grid>
      <Grid
        container
        item
        direction="column"
        wrap="nowrap"
        style={{ gap: "16px" }}
      >
        <Typography variant="subtitle1">Subscription Popups</Typography>
        <Grid container item className={classes.popupsContainer} xs>
          <SinglePopup
            activeShopEvents={activeShopEvents}
            content={popupContent.subscribe}
            products={products}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Popups;
