import React, { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useVariation } from "@castiron/components";
import { Banner, Button, Card, Chip, Typography } from "@castiron/components";
import {
  areSubscriptionFeaturesActive,
  Plan,
  Subscription,
} from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import Dinero from "dinero.js";
import moment from "moment-timezone";
import { getService } from "../../../firebase";
import { LayoutPageProps } from "../../Layout";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { openModal } from "../../../store/reducers/modalConductor";
import { getShopAction } from "../../../store/reducers/shops";
import SubscriptionBanner from "../../SubscriptionBanner";
import PaymentMethodDisplay from "../../SelectPlan/PlanComponents/PaymentMethodDisplay";
import Tooltip from "../../Tooltip";
import Spinner from "../../Spinner";
import { useLocation } from "react-router-dom";
import { PlanContext, PlanContextData } from "../../SelectPlan/PlanContext";
import { planRepository } from "../../../domain";
import { set } from "lodash";
import UpdatedPricingBanner from "../../SelectPlan/PlanComponents/UpdatedPricingBanner";
import FreeCustomDomainBanner from "../Domains/FreeCustomDomainBanner";
import * as helpscout from "../../../lib/helpscount";
import ReferralBlock from "../../ReferralPrompts/ReferralBlock";
import PromoBlock, {
  showFirstMonthPromoBlock,
} from "../../ReferralPrompts/PromoBlock";

const getFutureInvoiceService = getService(
  "subscriptions",
  "getfutureinvoicetotals"
);
const getBalanceService = getService("stripe", "getbalance");
const uncancelSubscriptionService = getService(
  "subscriptions",
  "uncancelsubscription"
);
const listCustomerPlanPaymentsService = getService(
  "subscriptions",
  "listcustomerplanpayments"
);
const cancelSubscriptionService = getService(
  "subscriptions",
  "cancelsubscription",
  { version: 2 }
);

interface ProfitwellResult {
  additionalFeedback: string;
  cancelReason: string;
  errorMessage: string;
  salvageAttemptIntended: string;
  salvageAttemptResult: string;
  salvageAttemptUsed: string;
  salvageOfferResult: string;
  satisfactionInsight: string;
  status: string;
}

declare global {
  interface Window {
    profitwell(
      action: "start" | "init_cancellation_flow",
      {}
    ): Promise<ProfitwellResult>;
  }
}

interface Props extends LayoutPageProps {
  openPaymentModal?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  apContainer: {},
  apTitle: {
    fontWeight: 600,
    marginBottom: 8,
  },
  apValue: {},
  ccErrorButton: {
    color: "inherit",
    padding: 0,
  },
  cancelSubButton: {
    padding: 16,
    "&:hover": {
      backgroundColor: theme.branding.red.light,
      borderColor: theme.branding.red.primary,
    },
    "&:disabled": {
      opacity: 0.5,
    },
  },
  changePaymentButton: {
    [theme.breakpoints.down("xs")]: {
      padding: 11,
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      width: "100%",
      margin: 0,
    },
  },
  cpContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: "12px",
    padding: 24,

    "& > .MuiGrid-item": {
      paddingLeft: 0,
    },
  },
  cpChip: {
    fontWeight: 600,
    lineHeight: "20px",
    padding: "0px 7px",
  },
  cpChipCancelled: {
    color: theme.branding.red.primary,
    fontWeight: 600,
    lineHeight: "20px",
    padding: "0px 7px",
  },
  cpDescriptor: {
    color: theme.branding.gray[700],
  },
  cpTitle: {
    color: theme.branding.v2.plum[500],
    fontSize: 18,
    lineHeight: "28px",
  },
  detailsContainer: {
    "& > section": {
      marginBottom: 0,
    },
  },
  moneyBanner: {
    marginTop: 8,
    fontSize: 14,
  },
  redeemCouponBox: {
    backgroundColor: theme.branding.v2.gray[100],
    borderRadius: 12,
    marginTop: 16,
    padding: 16,
    maxWidth: 400,
  },
  subscriptionBanner: {
    marginBottom: 16,
  },
}));

type LocalStarterType = {
  lastPaymentDate?: number;
  nextPaymentDate?: number;
  plan: {
    name: string;
  };
  price: {
    amount: number;
    frequency: string;
  };
  status: string;
};

// Only used if previous subscription plan was canceled
const localStarterSubscription: LocalStarterType = {
  lastPaymentDate: moment().unix(),
  nextPaymentDate: moment().add(1, "month").unix(),
  plan: {
    name: "Starter",
  },
  price: {
    amount: 0,
    frequency: "monthly",
  },
  status: "canceled",
};

const SubscriptionPlans: React.FC<Props> = (props) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, openPaymentModal } =
    props;
  const classes = useStyles();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [activeSubscription, setActiveSubscription] = useState<
    Subscription | LocalStarterType
  >(localStarterSubscription);
  const [currentPaymentTaxes, setCurrentPaymentTaxes] = useState(0);
  const [isSubmittingResub, setIsSubmittingResub] = useState(false);
  const [discountText, setDiscountText] = useState<string>("");
  const [forcePlan, setForcePlan] = useState<Plan>();
  const [lastPaymentAmount, setLastPaymentAmount] = useState<number | null>(
    null
  );
  const [endDate, setEndDate] = useState("");
  const [showError, setShowError] = useState<boolean>(false);

  const location = useLocation<{ plan: string; frequency: string }>();
  const { plan, frequency } = location.state || {};

  const fullDateFormat = "MMMM D, YYYY";

  const { account, isShopLoading, me, shop, userState, discount } =
    useAppSelector((state) => ({
      account: state.shops.account,
      isShopLoading: state.shops.loading,
      me: state.users.me,
      shop: state.shops.shop,
      userState: state.shops.userState,
      discount: state.shops.discount,
    }));

  const subscription = account?.subscription;
  const tier = account?.tier;

  useEffect(() => {
    // setup profitwell
    window.profitwell("start", {
      user_email: me.email,
    });
  }, []);

  useEffect(() => {
    if (plan) {
      planRepository.get(plan).then(setForcePlan);
    }
  }, [plan]);

  useEffect(() => {
    const subDiscount = subscription?.discount;
    if (subDiscount) {
      if (
        !subDiscount.appliesToPlans ||
        subDiscount.appliesToPlans.includes(subscription.plan.id)
      ) {
        const amount =
          subDiscount.type === "percent"
            ? `${subDiscount.amount}%`
            : `${Dinero({ amount: subDiscount.amount || 0 }).toFormat(
                "$0.00"
              )}`;
        const paymentText = !!subscription?.lastPaymentDate ? "next" : "first";
        setDiscountText(` + ${amount} off your ${paymentText} payment!`);
      } else {
        setDiscountText("");
      }
    }
    const nextBillingDate = moment
      .unix(account?.subscription?.nextPaymentDate)
      .format("LL");
    setEndDate(nextBillingDate);
  }, [account]);

  const planCancelDate = moment
    .unix(account?.subscription?.nextPaymentDate)
    .format("MMM D");
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState<number>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const hasCreditCardErrors = subscription?.status === "payment-failed";

  const subscriptionBannerExperiment = useVariation(
    "Subscription Upgrade Banner Test"
  );
  const isVariant =
    subscriptionBannerExperiment?.name === "Plans & Pricing Page";

  const trialDaysLeft =
    moment.unix(subscription?.trialEndDate).diff(moment(), "days") + 1;

  const openChangePaymentMethodModal = () => {
    trackEvent("Shop View Change Payment Modal", {
      shopId: shop.id,
      location: "changePaymentMethod",
      tier: tier,
      currentPlan: subscription?.plan.name,
    });

    dispatch(
      openModal({
        modalType: "CHANGE_PAYMENT_MODAL",
        modalProps: {
          open: true,
          forcePlanDetails: {
            plan: subscription.plan,
            price: subscription.price,
          },
        },
      })
    );
  };

  const getShop = async () => {
    await dispatch(getShopAction(shop.id));
  };

  useEffect(() => {
    setHeaderCTAs([]);
    getShop();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle("My Subscription");
    setBackLocation(true);

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("displayPaymentUpdate") === "true") {
      openChangePaymentMethodModal();
    }

    return () => {
      setPageTitle("");
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    if (account) {
      setIsLoading(true);
      getBalanceService({}).then((resp) => setBalance(Math.abs(resp.balance)));
      if (areSubscriptionFeaturesActive(subscription)) {
        setActiveSubscription(subscription);

        const getTaxes = async () => {
          const taxes = await getFutureInvoiceService({
            address: account?.billingAddress,
            planId: subscription.plan.id,
            priceId: subscription.price.id,
          });

          setCurrentPaymentTaxes(taxes.tax);
          setIsLoading(false);
        };

        if (account?.billingAddress) {
          getTaxes();
        }
      }
      setIsLoading(false);
    }
  }, [account]);

  // Hide Learn more for now
  // useEffect(() => {
  //   const headerCTAs = isMobile
  //     ? [
  //         <EllipsisMenu
  //           options={[
  //             {
  //               display: 'Learn More',
  //               action: () => window.open('https://www.castiron.me/pricing', '_blank'),
  //             },
  //           ]}
  //         />,
  //       ]
  //     : [
  //         <Button variant="outlined" onClick={() => window.open('https://www.castiron.me/pricing', '_blank')}>
  //           Learn More
  //         </Button>,
  //       ];
  //   setHeaderCTAs(headerCTAs);
  //   return () => setHeaderCTAs([]);
  // }, [isMobile]);

  const openChangePlanModal = () => {
    const selectedPlan = forcePlan || subscription?.plan;
    const selectedPrice = frequency
      ? selectedPlan?.prices.find((p) => p.frequency === frequency)
      : subscription?.price;

    trackEvent("Shop View Upgrade Modal", {
      shopId: shop.id,
      location: "plansPricingPage",
      tier: tier,
      currentPlan: subscription?.plan.name,
    });
    dispatch(
      openModal({
        modalType: "CHANGE_PLANS_MODAL",
        modalProps: {
          open: true,
          step: openPaymentModal ? 2 : 1,
          selectedPlan: {
            plan: selectedPlan,
            price: selectedPrice,
          },
        },
      })
    );
  };

  const handleCancelSubscription = async (cancelReason) => {
    setIsLoading(true);
    const cancelResponse = await cancelSubscriptionService({
      reason: cancelReason || "Plan Canceled",
      renewalDate: endDate,
    });

    if (!cancelResponse?.success) {
      setShowError(true);
    }
    setIsLoading(false);
  };

  const openCancelPlanModal = () => {
    setIsLoading(true);
    window
      .profitwell("init_cancellation_flow", {
        subscription_id: subscription.plan.id,
      })
      .then((result) => {
        console.debug(result);
        if (result.status == "chose_to_cancel") {
          handleCancelSubscription(result.cancelReason);
        }
      });
    setIsLoading(false);
  };

  const planChip = {
    currentSubscriber: (
      <Chip colorScheme="blue" className={classes.cpChip}>
        My Current Plan
      </Chip>
    ),
    cancellingSubscriber: (
      <Chip
        colorScheme="quotesLightRed"
        bold
        className={classes.cpChipCancelled}
      >
        Ends on {planCancelDate}
      </Chip>
    ),
  };

  useEffect(() => {
    if (account?.integrations?.stripe?.customerId) {
      const listCustomerPlanPayments = async () => {
        const pm = await listCustomerPlanPaymentsService({
          customerId: account?.integrations?.stripe?.customerId,
          limit: 1,
        });
        if (pm) {
          setLastPaymentAmount(pm.data[0].amount);
        } else {
          setLastPaymentAmount(null);
        }
      };
      listCustomerPlanPayments();
    }
  }, [account]);

  const getCurrentPlanButtonText = () => {
    switch (userState) {
      case "inTrial":
        if (subscription?.paymentMethod) return "Change Plan";
      case "legacyInTrial":
        return "Upgrade Now";
      case "currentSubscriber":
        return "Change Plan";
      case "cancellingSubscriber":
        return "Resume Subscription";
      case "legacyNewSubscriber":
      case "legacyTrialCompleted":
        return "See Plans";
      default:
        return "Change Plan";
    }
  };

  const getCreditedPaymentDate = () => {
    if (subscription?.nextPaymentDate) {
      if (subscription?.price?.frequency === "yearly") {
        return moment
          .unix(subscription?.nextPaymentDate)
          .add(Math.floor(balance / subscription?.price?.amount), "y")
          .format("MM/DD/YYYY");
      } else {
        return moment
          .unix(subscription?.nextPaymentDate)
          .add(Math.floor(balance / subscription?.price?.amount), "M")
          .format("MM/DD/YYYY");
      }
    } else {
      return "next payment date";
    }
  };

  const getDescriptor = () => {
    let descBase;

    switch (userState) {
      case "inTrial":
      case "legacyInTrial":
        descBase = "Free Trial";
      case "currentSubscriber":
      case "cancellingSubscriber":
        descBase =
          Dinero({ amount: activeSubscription?.price?.amount || 0 }).toFormat(
            "$0.00"
          ) +
          " / " +
          (activeSubscription?.price?.frequency === "yearly"
            ? "year"
            : "month");
      case "legacyNewSubscriber":
      case "legacyTrialCompleted":
        descBase =
          Dinero({ amount: activeSubscription?.price?.amount || 0 }).toFormat(
            "$0.00"
          ) +
          " / " +
          (activeSubscription?.price?.frequency === "yearly" ? "year" : "mo.") +
          " + " +
          tier.castironTakeRate +
          "%";
      default:
        descBase =
          Dinero({ amount: activeSubscription?.price?.amount || 0 }).toFormat(
            "$0.00"
          ) +
          " / " +
          (activeSubscription?.price?.frequency === "yearly"
            ? "year"
            : "month");
    }
    descBase +=
      " " +
      `${
        currentPaymentTaxes > 0
          ? Dinero({ amount: currentPaymentTaxes }).toFormat("$0.00")
          : ""
      }` +
      "+ sales tax";
    return descBase + discountText;
  };

  const getEmptyState = () => {
    return userState == "inTrial" || userState == "legacyInTrial"
      ? "-"
      : "None";
  };

  const openRedeemCouponModal = () => {
    dispatch(
      openModal({
        modalType: "REDEEM_COUPON_MODAL",
        modalProps: {
          open: true,
        },
      })
    );
  };

  const resumeSubscription = async () => {
    setIsSubmittingResub(true);
    const formattedBillingDate = moment
      .unix(account?.subscription?.nextPaymentDate)
      .format("LL");

    const response = await uncancelSubscriptionService({
      nextBillingDate: formattedBillingDate,
    });

    if (response?.success) {
      dispatch(
        openModal({
          modalType: "RESUME_SUBSCRIPTION_MODAL",
          modalProps: {
            open: true,
            nextBillingDate: formattedBillingDate,
          },
        })
      );
    }

    setIsSubmittingResub(false);
  };

  const CurrentPlanContainer = () => {
    return (
      <>
        {isVariant && (
          <Grid className={classes.subscriptionBanner}>
            <SubscriptionBanner />
          </Grid>
        )}
        <Grid
          className={classes.cpContainer}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid container spacing={1} direction="column" item xs={8}>
            <Grid item>{planChip[userState]}</Grid>
            <Grid item>
              <Typography variant="h6" className={classes.cpTitle}>
                {activeSubscription?.plan?.name || "Starter"} Plan
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className={classes.cpDescriptor}>
                {getDescriptor()}
              </Typography>
            </Grid>
          </Grid>
          {/* Disabling for now due to new Marketplace tier and moving away from subscriptions <Grid container item xs={4} justify="flex-end">
            <Button
              variant="outlined"
              disabled={
                isSubmittingResub ||
                (subscription?.status === 'trial' && !!shop?.config?.freeCustomDomain && !!subscription?.paymentMethod)
              }
              onClick={() => {
                // trackEvent('SubscriptionPlans', 'Upgrade');
                userState === 'cancellingSubscriber' ? resumeSubscription() : openChangePlanModal();
              }}
            >
              {getCurrentPlanButtonText()}
            </Button>
          </Grid>
          {userState === 'currentSubscriber' && (
            <Grid container item xs={12} md={6}>
              <Grid
                container
                item
                className={classes.redeemCouponBox}
                justify="center"
                alignItems="center"
                wrap="nowrap"
              >
                <Typography variant="body1" className={classes.cpDescriptor}>
                  Need to redeem a coupon code?
                </Typography>
                <Button variant="text" onClick={openRedeemCouponModal} style={{ padding: '0 0 0 16px' }}>
                  Redeem
                </Button>
              </Grid>
            </Grid>
          )} */}
        </Grid>
      </>
    );
  };

  const PaymentMethodCard = () => {
    const changePaymentButton = (
      <Button
        variant="outlined"
        onClick={openChangePaymentMethodModal}
        className={classes.changePaymentButton}
      >
        Update
      </Button>
    );

    return (
      <Grid container>
        <Card
          title="Payment Method"
          sideMessage={
            areSubscriptionFeaturesActive(subscription) &&
            subscription?.paymentMethod
              ? changePaymentButton
              : null
          }
        >
          {subscription?.paymentMethod ? (
            <Grid container direction="column" spacing={2}>
              {hasCreditCardErrors && (
                <Grid item>
                  <Banner variant="error">
                    <Typography variant="body2">
                      Error processing your subscription. Please update your
                      payment method.
                    </Typography>
                  </Banner>
                </Grid>
              )}
              <Grid item>
                <Box style={{ padding: "12px" }}>
                  <PaymentMethodDisplay
                    brand={subscription?.paymentMethod?.brand}
                    last4={subscription?.paymentMethod?.last4}
                    expirationDate={subscription?.paymentMethod?.expirationDate}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1">None</Typography>
          )}
        </Card>
      </Grid>
    );
  };

  const ActivePlanCard = () => {
    return (
      <Grid container className={classes.detailsContainer}>
        <Card
          title="Subscription Details"
          sideMessage={
            subscription.status == "pending-canceled" ? (
              <Button variant="contained" onClick={resumeSubscription}>
                Resubscribe
              </Button>
            ) : (
              <Button variant="outlined" onClick={openChangePlanModal}>
                Change Frequency
              </Button>
            )
          }
        >
          <Grid container direction="column" spacing={2}>
            <Grid
              container
              direction="column"
              item
              className={classes.apContainer}
            >
              <Typography variant="subtitle2" className={classes.apTitle}>
                Status
              </Typography>
              <Grid container direction="row" justify="space-between">
                <Typography variant="body1" className={classes.apValue}>
                  {subscription?.status === "active" ||
                  subscription?.status === "trial" ? (
                    <Chip colorScheme="success">Active</Chip>
                  ) : (
                    <Chip colorScheme="error">Inactive</Chip>
                  )}
                </Typography>
              </Grid>
              <Typography
                variant="subtitle2"
                className={classes.apTitle}
                style={{ marginTop: 5 }}
              >
                Billing Cycle
              </Typography>
              <Grid container direction="row" justify="space-between">
                <Typography variant="body1" className={classes.apValue}>
                  {activeSubscription?.price?.frequency === "monthly"
                    ? "Monthly"
                    : "Yearly"}
                </Typography>
                <Typography variant="body1" className={classes.apValue}>
                  {Dinero({
                    amount: activeSubscription?.price?.amount || 0,
                  }).toFormat("$0.00")}
                  {"/"}
                  {activeSubscription?.price?.frequency === "monthly"
                    ? "month"
                    : "year"}
                  {currentPaymentTaxes > 0 && (
                    <>
                      {" "}
                      +{" "}
                      {Dinero({ amount: currentPaymentTaxes }).toFormat(
                        "$0.00"
                      )}{" "}
                      sales tax
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              item
              className={classes.apContainer}
            >
              <Typography variant="subtitle2" className={classes.apTitle}>
                Last Payment
              </Typography>
              {subscription?.paymentMethod && subscription?.lastPaymentDate ? (
                <Grid container direction="row" justify="space-between">
                  <Typography variant="body1" className={classes.apValue}>
                    {moment
                      .unix(subscription?.lastPaymentDate)
                      .format(fullDateFormat)}
                  </Typography>
                </Grid>
              ) : (
                <Typography variant="body1" className={classes.apValue}>
                  {getEmptyState()}
                </Typography>
              )}
            </Grid>
            <Grid
              container
              direction="column"
              item
              className={classes.apContainer}
            >
              <Typography variant="subtitle2" className={classes.apTitle}>
                Next Scheduled Payment
              </Typography>
              {subscription?.paymentMethod &&
              areSubscriptionFeaturesActive(subscription) &&
              subscription?.status != "pending-canceled" ? (
                <Grid container direction="row" justify="space-between">
                  <Typography variant="body1" className={classes.apValue}>
                    {moment
                      .unix(subscription?.nextPaymentDate)
                      .format(fullDateFormat)}
                  </Typography>
                </Grid>
              ) : (
                <Typography variant="body1" className={classes.apValue}>
                  {getEmptyState()}
                </Typography>
              )}
            </Grid>
            {balance !== 0 && userState == "currentSubscriber" && (
              <Grid
                container
                direction="column"
                item
                className={classes.apContainer}
              >
                <Grid container direction="row">
                  <Typography variant="subtitle2" className={classes.apTitle}>
                    Plan Credits
                  </Typography>
                  <Tooltip
                    title="Leftover balance from referrals or plan cancellation."
                    style={{ marginBottom: 8 }}
                  />
                </Grid>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="body1" className={classes.apValue}>
                    Remaining Balance
                  </Typography>
                  <Typography variant="body1" className={classes.apValue}>
                    {`+ ${Dinero({ amount: balance }).toFormat("$0,0.00")}`}
                  </Typography>
                </Grid>
                <Grid container direction="row" justify="space-between">
                  <Banner variant="money" className={classes.moneyBanner}>
                    Your {Dinero({ amount: balance }).toFormat("$0,0.00")} in
                    plan credit will be applied to future billing cycles until
                    it runs out. Your card won’t be charged again until{" "}
                    {getCreditedPaymentDate()}.
                  </Banner>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    );
  };

  useEffect(() => {
    if (openPaymentModal && plan && forcePlan) {
      openChangePlanModal();
    } else if (openPaymentModal && !plan) {
      openChangePlanModal();
    }
  }, [openPaymentModal, plan, forcePlan]);

  return (
    <Grid container>
      <Helmet>
        <title>My Subscription | Nourysh</title>
      </Helmet>
      {isLoading || isShopLoading ? (
        <Spinner show={isLoading || isShopLoading || isSubmittingResub} />
      ) : (
        <Grid
          container
          direction="column"
          spacing={3}
          className={classes.container}
        >
          {hasCreditCardErrors && (
            <Grid item>
              <Banner variant="error">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={8} sm={10}>
                    <Typography variant="body2">
                      We were unable to process your latest payment. Please
                      update your payment method to continue enjoying the
                      features of the {subscription?.plan?.name} Plan.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      className={classes.ccErrorButton}
                      onClick={openChangePaymentMethodModal}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Banner>
            </Grid>
          )}
          <Grid item>
            <ActivePlanCard />
          </Grid>
          <Grid item style={{ paddingBottom: 0 }}>
            <PaymentMethodCard />
          </Grid>
          <Grid item style={{ paddingTop: 0 }}>
            {(subscription?.status === "active" ||
              subscription?.status === "trial") &&
              subscription?.paymentMethod && (
                <>
                  <Button
                    variant="outlined"
                    className={classes.cancelSubButton}
                    onClick={openCancelPlanModal}
                    disableRipple
                    fullWidth={isMobile}
                    disabled={
                      subscription?.status === "trial" &&
                      !!shop?.config?.freeCustomDomain
                    }
                  >
                    <Typography
                      variant="button"
                      style={{ color: theme.branding.red.primary }}
                    >
                      Cancel Subscription
                    </Typography>
                  </Button>
                  {showError && (
                    <Grid style={{ marginTop: 24 }}>
                      <Banner variant="error">
                        <Typography
                          variant="body4"
                          style={{ color: theme.branding.red.primary }}
                        >
                          An error occurred while trying to cancel your
                          subscription. Please refresh your browser and try
                          again.
                        </Typography>
                      </Banner>
                    </Grid>
                  )}
                  {subscription?.status === "trial" &&
                    !!shop?.config?.freeCustomDomain && (
                      <Typography
                        style={{
                          marginTop: 16,
                          textAlign: isMobile ? "center" : "left",
                        }}
                        variant="body2"
                      >
                        You’re still in the 14 day free trial period. Please{" "}
                        <span
                          onClick={() => helpscout.popChat()}
                          style={{
                            color: theme.branding.v2.plum[500],
                            cursor: "pointer",
                          }}
                        >
                          contact customer support
                        </span>{" "}
                        to cancel your active subscription.
                      </Typography>
                    )}
                </>
              )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SubscriptionPlans;
