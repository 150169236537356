import React, { ReactNode, useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  ButtonBase,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Pill, Typography } from "@castiron/components";
import { useTracking } from "@castiron/utils";
import { useAppDispatch } from "../../../hooks";
import { closeModal } from "../../../store/reducers/modalConductor";
import ModalWrapper from "../../RootModal/ModalWrapper";
import { TemplateDisplay } from "./templatesUtils";

export type Props = {
  show: boolean;
  context: "email" | "sms";
  templates: TemplateDisplay[];
  scrollTo?: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  contentContainer: {
    marginBottom: 24,
    padding: "24px 24px 0px 24px",
    textAlign: "center",
    overflowY: "scroll",
    "scrollbar-width": "thin",
    "scrollbar-color": theme.branding.v2.gray[400],
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.branding.v2.gray[600],
      "-webkit-border-radius": "12px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.branding.v2.gray[0],
    },
    "&::-webkit-scrollbar-track-piece:start": {
      background: "transparent",
    },
    "&-webkit-scrollbar-track-piece:end": {
      background: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
  templateContainer: {
    height: "100%",
    padding: "16px",
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: "12px",
    textAlign: "left",
    gap: 16,
  },
  templateIconContainer: {
    height: 72,
    minWidth: 72,
    maxWidth: 72,
    borderRadius: "12px",
    backgroundColor: theme.branding.v2.gray[100],
  },
  iconContainer: {
    fontSize: 63,
  },
  modalContent: {
    height: "100%",
    overflowY: "hidden",
  },
  navContainer: {
    padding: "24px 0",
    gap: "12px",
    borderRight: `1px solid ${theme.branding.v2.gray[300]}`,
    position: "sticky",
  },
  navItem: {
    padding: 10,
    cursor: "pointer",
  },
  paperClass: {
    borderRadius: 16,
    padding: "0 32px 32px",
    gap: "16px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      padding: "0 16px 32px",
    },
    [theme.breakpoints.up("sm")]: {
      height: 800,
    },
  },
  templateCategory: {
    gap: "16px",
  },
  templateCategoriesContainer: {
    gap: "24px",
    padding: "0 8px 32px 0px",
    overflowY: "scroll",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "24px",
    },
  },
  titleContainer: {
    backgroundColor: theme.branding.v2.gray[0],
    gap: "8px",
    position: "sticky",
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    padding: "32px 0 16px",
  },
}));

const TemplatesDisplayModal: React.FC<Props> = (props: Props) => {
  const { show, context, templates, scrollTo } = props;
  const classes = useStyles(props);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { trackEvent } = useTracking();

  const formattedTemplates = templates.map((template) => ({
    scrollTo: template.eventTag || template.category,
    title: template.category === "Basic" ? "Basics" : template.category,
  }));
  const navItems = _.uniqBy(formattedTemplates, (template) => template.title);
  const groupedTemplates = _.groupBy(
    templates,
    (template) => template.category
  );

  const [isLoaded, setIsLoaded] = useState(false);

  //have to use callback ref in order to trigger scroll once dom elements are fully loaded
  const contentRef = useCallback((node) => {
    if (node !== null) setIsLoaded(true);
  }, []);

  const handleScroll = (scrollLocation?: string) => {
    const scrollTo = document.getElementById(
      `${!!scrollLocation ? scrollLocation.toLowerCase() : "basic"}-templates`
    );

    scrollTo?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    if (!!scrollTo && isLoaded) {
      handleScroll(scrollTo);
    }
  }, [scrollTo, isLoaded]);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const getCategoryId = (category: string, templates: TemplateDisplay[]) => {
    const tag = templates[0].eventTag;
    const location = !!tag ? tag : category;

    return `${location?.toLowerCase()}-templates`;
  };

  const generateTemplateDisplayContainer = ({
    icon,
    iconBackgroundColor,
    pillText,
    name,
    subheader,
    route,
    event,
    index,
  }: {
    icon: string;
    iconBackgroundColor: string;
    pillText?: string;
    name: string;
    subheader: string;
    route: string;
    event?: string;
    index: number;
  }): ReactNode => {
    return (
      <ButtonBase
        key={`single-send-email-${index}`}
        onClick={() => {
          event && trackEvent(event);
          history.push(route);
          handleClose();
        }}
      >
        <Grid
          container
          item
          className={classes.templateContainer}
          wrap="nowrap"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            style={{ backgroundColor: iconBackgroundColor }}
            justify="center"
            alignContent="center"
            className={classes.templateIconContainer}
          >
            <Typography variant="h4" style={{ fontSize: "24px" }}>
              {icon}
            </Typography>
          </Grid>
          <Grid container item direction="column" wrap="nowrap">
            {pillText && (
              <Pill
                content={pillText}
                variant={pillText === "New!" ? "blue" : "gray"}
              />
            )}
            <Typography variant="subtitle1" style={{ marginTop: 8 }}>
              {name}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: theme.branding.v2.gray[500] }}
            >
              {subheader}
            </Typography>
          </Grid>
        </Grid>
      </ButtonBase>
    );
  };

  return (
    <ModalWrapper
      paperClass={classes.paperClass}
      fullScreen={isXsMobile}
      show={show}
      onClose={handleClose}
    >
      <Grid container className={classes.titleContainer}>
        <Grid container item justify="space-between">
          <Typography variant="h4">
            {context === "email" ? "Email" : "SMS"} Templates
          </Typography>
          <Grid
            container
            item
            alignItems="flex-start"
            justify="flex-end"
            xs={2}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="body1">
          Enhance your {context === "email" ? "email" : "SMS"} marketing in no
          time with our prebuilt templates.
        </Typography>
      </Grid>
      <Grid
        container
        direction={isXsMobile ? "column" : "row"}
        wrap="nowrap"
        className={classes.modalContent}
      >
        {!isXsMobile && (
          <Grid
            container
            item
            direction="column"
            className={classes.navContainer}
            xs={3}
          >
            <Grid
              item
              onClick={() => handleScroll()}
              className={classes.navItem}
              key={`modal-nav-all`}
            >
              <Typography variant="body2">All Templates</Typography>
            </Grid>
            {navItems.map((item, index) => (
              <Grid
                item
                onClick={() => handleScroll(item.scrollTo)}
                className={classes.navItem}
                key={`modal-nav-${item.title}-${index}`}
              >
                <Typography variant="body2">{item.title}</Typography>
              </Grid>
            ))}
            <Grid className={classes.spacer}></Grid>
          </Grid>
        )}
        <Grid
          ref={contentRef}
          container
          item
          direction="column"
          wrap="nowrap"
          xs={12}
          sm={9}
          className={classes.templateCategoriesContainer}
        >
          {Object.entries(groupedTemplates).map(
            ([category, templates], index) => (
              <Grid
                container
                item
                direction="column"
                key={`templates-${category}-${index}`}
                id={getCategoryId(category, templates)}
                className={classes.templateCategory}
              >
                <Typography variant="subtitle1">
                  {category === "Basic" ? "Basics" : category}
                </Typography>
                {templates.map((template, index) =>
                  generateTemplateDisplayContainer({ ...template, index })
                )}
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default TemplatesDisplayModal;
