import React, { useCallback, useEffect, useState } from "react";
import Dinero from "dinero.js";
import {
  Chip as MuiChip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Chip,
  SimpleStyledRadio,
  Typography,
} from "@castiron/components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { closeModal } from "../../../store/reducers/modalConductor";
import { UserState } from "../../../store/reducers/shops";
import { NextStep, SelectedSubscription } from "../PlanFlow";
import { Price, PriceFrequency } from "@castiron/domain";

type Props = {
  selectedSub: SelectedSubscription;
  nextStep: NextStep;
  setModalHeader?: React.Dispatch<React.SetStateAction<string>>;
  couponCode?: string;
};

type FormattedPrice = {
  price: Price;
  yearlyPrice: number;
  monthlyPrice: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    display: "block",
    "& .MuiFormControlLabel-label": {
      padding: 0,
      width: "100%",
    },
  },
  formGroup: {
    "& .MuiFormControlLabel-root": {
      margin: 0,
    },
  },
  mobileFooter: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: "16px 16px 24px",
    backgroundColor: theme.branding.gray[100],
  },
  button: {
    width: 80,
    marginTop: 24,
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
  },
  oneTimeCharge: {
    color: theme.branding.gray[700],
    marginTop: 4,
  },
  option: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "space-between",
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 16,
    padding: "7px 16px 8px 6px",
    marginBottom: 8,
    flexWrap: "nowrap",
  },
  optionLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginRight: "16vw",
    },
  },
  optionRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  optionWrapper: {
    border: `1px solid transparent`,
  },
  savingsChip: {
    backgroundColor: theme.branding.green.light,
    maxWidth: 120,
    marginBottom: 4,
  },
  selectedBorder: {
    border: `2px solid ${theme.branding.v2.plum[500]} !important`,
  },
  wrapper: {
    width: "100%",
    padding: "24px 40px",
    display: "flex",
    justifyContent: "center",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },
}));

const toFormattedPrice = (price: Price): FormattedPrice => {
  if (!price) return undefined;

  let monthlyPrice;
  let yearlyPrice;

  if (price.frequency === "yearly") {
    yearlyPrice = price.amount;
    const yearlyPriceDiscounted = yearlyPrice - (price?.discount || 0);
    yearlyPrice = yearlyPriceDiscounted;
    monthlyPrice = Math.round(yearlyPriceDiscounted / 12);
  } else if (price.frequency === "monthly") {
    monthlyPrice = price.amount;
    yearlyPrice = monthlyPrice * 12;
  }

  return {
    price,
    yearlyPrice,
    monthlyPrice,
  };
};

const planRenewalStates: UserState[] = [
  "inTrial",
  "legacyInTrial",
  "subscriptionEnded",
  "trialExpired",
  "legacyTrialCompleted",
];

const SelectPaymentFrequency: React.FC<Props> = (props: Props) => {
  const { selectedSub, nextStep, setModalHeader, couponCode } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { account, userState } = useAppSelector((state) => ({
    account: state.shops.account,
    userState: state.shops.userState,
  }));

  const [prices, setPrices] = useState<FormattedPrice[]>([]);
  const [chosenFrequency, setChosenFrequency] = useState<PriceFrequency>(
    selectedSub?.price?.frequency || "yearly"
  );
  const [chosenPrice, setChosenPrice] = useState<FormattedPrice>(
    toFormattedPrice(selectedSub.price)
  );
  const [priceChangeType, setPriceChangeType] = useState<
    "upgrade" | "downgrade" | "frequency"
  >("downgrade");

  const getSelectedPrices = () => {
    if (selectedSub.plan.name !== "Starter") {
      const basePrices = selectedSub.plan.prices;
      const formattedPrices = basePrices.map(toFormattedPrice);

      const sortedPrices = formattedPrices.sort((a, b) =>
        b.price.frequency > a.price.frequency
          ? 1
          : a.price.frequency > b.price.frequency
          ? -1
          : 0
      );
      const initialPrice = formattedPrices.find(
        (p) => p.price.frequency === "yearly"
      );
      const exisitingPrice = formattedPrices.find(
        (p) => p.price.id === selectedSub.price.id
      );

      setPrices(sortedPrices);

      const currentFreq = account?.subscription?.price.frequency;
      const equivalentPrice = basePrices.find(
        (p) => p.frequency === currentFreq
      );
      if (account?.subscription?.plan?.name === selectedSub.plan.name) {
        /* safe to assume this is a frequency change */
        setPriceChangeType("frequency");
      } else if (
        account?.subscription?.price?.amount &&
        equivalentPrice?.amount
      ) {
        setPriceChangeType(
          account.subscription.price.amount < equivalentPrice.amount
            ? "upgrade"
            : "downgrade"
        );
      }

      selectedSub.price.id === ""
        ? setChosenPrice(initialPrice)
        : setChosenPrice(exisitingPrice);
    }
  };

  useEffect(() => {
    getSelectedPrices();

    setModalHeader && setModalHeader(`${selectedSub.plan.name} Plan`);
  }, [selectedSub, priceChangeType]);

  useEffect(() => {
    if (
      !planRenewalStates.includes(userState) &&
      selectedSub.plan?.prices.find(
        (p) => p.id === account?.subscription?.price.id
      )
    ) {
      /* we should select by default the other frequency */
      setChosenFrequency(
        account?.subscription?.price.frequency === "yearly"
          ? "monthly"
          : "yearly"
      );
    }
  }, [account, selectedSub]);

  const isSelected = (p: FormattedPrice) =>
    p?.price.frequency === chosenFrequency;

  const choiceAllowed = useCallback(
    (chosen: FormattedPrice) => {
      return (
        planRenewalStates.includes(userState) ||
        chosen.price.id !== account?.subscription?.price.id
      );
    },
    [account, userState]
  );

  const setSelection = useCallback(
    (chosen: FormattedPrice) => {
      if (choiceAllowed(chosen)) {
        setChosenFrequency(chosen.price.frequency);
        setChosenPrice(chosen);
      }
    },
    [account, choiceAllowed]
  );

  return (
    <Grid container wrap={isMobile ? "wrap" : "nowrap"} alignItems="center">
      <FormControl className={classes.wrapper}>
        <RadioGroup
          value={chosenFrequency}
          onChange={(e) => {
            const chosen = prices?.find(
              (p) => p.price.frequency === e.target.value
            );
            setSelection(chosen);
          }}
          className={classes.formGroup}
        >
          {prices?.map((p: FormattedPrice) =>
            p.price.frequency === "yearly" ? (
              <FormControlLabel
                value="yearly"
                control={<Radio style={{ display: "none" }} />}
                className={classes.formControl}
                label={
                  <div
                    className={`${!isSelected(p) && classes.optionWrapper}`}
                    onClick={() => setSelection(p)}
                  >
                    <Grid
                      container
                      item
                      className={`${isSelected(p) && classes.selectedBorder} ${
                        classes.option
                      }`}
                    >
                      <Grid item className={classes.optionLeft}>
                        <SimpleStyledRadio
                          checked={isSelected(p)}
                          value="yearly"
                          disabled={!choiceAllowed(p)}
                        />
                        <Typography variant="body2">Pay Annually</Typography>
                      </Grid>
                      <Grid
                        item
                        className={classes.optionRight}
                        style={{ marginTop: 9 }}
                      >
                        {!!p.price.discount &&
                          p.price.id !== account?.subscription?.price?.id && (
                            <MuiChip
                              className={classes.savingsChip}
                              label={
                                <Typography variant="caption2">
                                  Save{" "}
                                  {Dinero({
                                    amount: p?.price.discount,
                                  }).toFormat("$0")}
                                  !
                                </Typography>
                              }
                            />
                          )}
                        {p.price.id === account?.subscription?.price?.id && (
                          <Chip colorScheme="blue">My Current Plan</Chip>
                        )}
                        <Typography variant="subtitle1">
                          {`${Dinero({ amount: p.monthlyPrice }).toFormat(
                            "$0.00"
                          )} / month`}
                        </Typography>
                        <Typography
                          variant="body4"
                          className={classes.oneTimeCharge}
                        >
                          One time charge of{" "}
                          {Dinero({ amount: p.yearlyPrice }).toFormat("$0,0")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            ) : (
              <FormControlLabel
                value="monthly"
                control={<Radio style={{ display: "none" }} />}
                className={classes.formControl}
                label={
                  <div
                    className={`${!isSelected(p) && classes.optionWrapper}`}
                    onClick={() => setSelection(p)}
                  >
                    <Grid
                      container
                      item
                      className={`${isSelected(p) && classes.selectedBorder} ${
                        classes.option
                      }`}
                    >
                      <Grid item className={classes.optionLeft}>
                        <SimpleStyledRadio
                          checked={isSelected(p)}
                          value="monthly"
                          disabled={!choiceAllowed(p)}
                        />
                        <Typography variant="body2">Pay Monthly</Typography>
                      </Grid>
                      <Grid
                        item
                        className={classes.optionRight}
                        style={{ marginTop: 5 }}
                      >
                        <Grid container direction="column">
                          {p.price.id === account?.subscription?.price.id && (
                            <Chip colorScheme="blue">My Current Plan</Chip>
                          )}
                          <Grid item>
                            <Typography variant="subtitle1">
                              {`$${Dinero({
                                amount: p.monthlyPrice,
                              }).toRoundedUnit(0, "DOWN")}.00 / month`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            )
          )}
        </RadioGroup>
        {!isMobile && (
          <Grid container direction="row" justify="flex-end">
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                nextStep(1, {
                  ...selectedSub,
                  price: chosenPrice.price,
                });
              }}
            >
              Next
            </Button>
          </Grid>
        )}
      </FormControl>
      {isMobile && (
        <Grid className={classes.mobileFooter}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => dispatch(closeModal())}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => {
              nextStep(1, {
                ...selectedSub,
                price:
                  selectedSub.price.id === chosenPrice.price.id
                    ? selectedSub.price
                    : chosenPrice.price,
              });
            }}
          >
            Next
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectPaymentFrequency;
