import React, {
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { Box, DialogActions, Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  AutoAwesome,
  ButtonV2,
  CheckboxInput,
  MoneyInput,
  TextInput,
  Typography,
} from "@castiron/components";
import { BaseProduct, ProductType } from "@castiron/domain";
import { removeEmpty, useTracking } from "@castiron/utils";
import { FormikProps, useFormikContext } from "formik";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { closeModal, openModal } from "../../../store/reducers/modalConductor";
import { createProductAction } from "../../../store/reducers/products";
import { updateShopAction } from "../../../store/reducers/shops";
import { ProductModalProps } from "./AddProductModal";
import { getService } from "../../../firebase";
import Spinner from "../../Spinner";
import Tooltip from "../../Tooltip";

interface Props extends ProductModalProps {
  productType: ProductType;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "350px",
    },
  },
  footer: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: "12px 24px",
  },
  aiIcon: {
    height: 16,
    width: 16,
    color: theme.branding.v2.plum[500],
  },
}));

interface FormValues {
  title: string;
  price: number;
}

const generateDescriptionService = getService(
  "products",
  "generatedescription"
);

const NameAndPrice: React.FC<Props> = (props: Props) => {
  const {
    context,
    product,
    productType,
    setTitle,
    setFooter,
    setProduct,
    setStep,
    setStepNumber,
  } = props;
  const {
    errors,
    values,
    touched,
    setFieldValue,
    isSubmitting,
    validateField,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<FormValues>();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const [showSpinner, setShowSpinner] = useState<boolean>();

  const { shop } = useAppSelector((state) => ({
    shop: state.shops.shop,
  }));

  const priceHelperText =
    productType === "standard"
      ? "Additional pricing and product variations can be added later."
      : "Additional pricing and order form questions can be added later.";

  const submit = async (values: FormValues) => {
    await validateField("title");
    await validateField("price");
    await setFieldTouched("title", true);
    await setFieldTouched("price", true);
    if (
      values?.title &&
      !errors?.title &&
      (!errors?.price || values?.price > 0)
    ) {
      let description = undefined;
      // let generationId = undefined;
      // setShowSpinner(true);
      // const resp = await generateDescriptionService({
      //   title,
      // });
      // description = resp.description;
      // generationId = resp.generationId;
      // setFieldValue('description', description);
      // setFieldValue('generatedId', generationId);

      //set relevant store page to active on new product creation if the page isn't already enabled -- user cannot edit if page is enabled directly
      if (
        productType === "standard" &&
        !shop?.shopSubpageData?.isShopPageEnabled
      ) {
        await dispatch(
          updateShopAction({
            shop: {
              ...shop,
              shopSubpageData: {
                ...shop.shopSubpageData,
                isShopPageEnabled: true,
              },
            },
          })
        );
      } else if (
        productType === "custom" &&
        !shop?.shopSubpageData?.quotes?.enabled
      ) {
        await dispatch(
          updateShopAction({
            shop: {
              ...shop,
              shopSubpageData: {
                ...shop.shopSubpageData,
                quotes: {
                  enabled: true,
                  headline:
                    shop?.shopSubpageData?.quotes?.headline || "Custom Orders",
                  description:
                    shop?.shopSubpageData?.quotes?.description ||
                    "Please select an item below to view options, availability, and to begin a quote.",
                },
              },
            },
          })
        );
      } else if (
        productType === "event" &&
        !shop?.shopSubpageData?.ticketedEvents?.enabled
      ) {
        await dispatch(
          updateShopAction({
            shop: {
              ...shop,
              shopSubpageData: {
                ...shop.shopSubpageData,
                ticketedEvents: {
                  ...shop.shopSubpageData?.ticketedEvents,
                  enabled: true,
                  headline:
                    shop?.shopSubpageData?.ticketedEvents?.headline || "Events",
                  description:
                    shop?.shopSubpageData?.ticketedEvents?.description || "",
                },
              },
            },
          })
        );
      }

      if (!product) {
        const newProduct = {
          title: values.title,
          price:
            productType === "standard" || productType === "event"
              ? values.price
              : undefined,
          startingPrice: productType === "custom" ? values.price : undefined,
          description,
          shopId: shop.id,
          type: productType,
          unlimitedInventory:
            productType === "standard" || productType === "event"
              ? true
              : undefined,
          seoMetadata: {
            title: values.title,
            description: "",
          },
          status:
            shop.status === "active" || productType === "event"
              ? "inactive"
              : "active",
        };

        const createProductP = dispatch(createProductAction(newProduct));
        trackEvent("Product Created", {
          product: removeEmpty({
            ...newProduct,
            method: "admin",
          }),
          addMethod: "fromScratch",
        });

        trackEvent(
          newProduct.type === "standard"
            ? "Standard Product Created"
            : newProduct.type === "custom"
            ? "Custom Product Created"
            : "Event Created",
          {
            product: removeEmpty({
              ...newProduct,
              method: "admin",
            }),
            addMethod: "fromScratch",
          }
        );

        const createProductResponse = await createProductP;
        setProduct(createProductResponse.payload as BaseProduct);
      }
      setStep("tagProduct");
    }
  };

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  useEffect(() => {
    setTitle(
      context === "order-forms" || productType === "custom"
        ? "Describe Your Order Form"
        : context === "products" || productType === "standard"
        ? "Describe Your Product"
        : "New Event"
    );
    setStepNumber(1);
  }, []);

  useEffect(() => {
    setFooter(
      <DialogActions className={classes.footer}>
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <ButtonV2 variant="outlined" onClick={handleClose}>
              Cancel
            </ButtonV2>
          </Grid>
          <Grid item>
            <ButtonV2
              variant="contained"
              onClick={() => submit(values)}
              loading={isSubmitting}
            >
              Continue
            </ButtonV2>
          </Grid>
        </Grid>
      </DialogActions>
    );

    return () => {
      setFooter(null);
    };
  }, [values]);

  const openAboutModal = () => {
    dispatch(
      openModal({
        modalType: "ABOUT_CASTIRON_AI_MODAL",
        modalProps: {
          setIsModalPopped: (is: boolean) => {
            return;
          },
        },
      })
    );
  };

  return (
    <>
      <Box className={classes.container}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1">{`Get started with${
              productType === "event" ? " your " : " "
            }basic ${
              productType == "custom"
                ? "order form"
                : productType === "standard"
                ? "product"
                : "ticketed event or class"
            } information.`}</Typography>
          </Grid>
          <Grid item>
            <TextInput
              label={productType === "event" ? "Title" : "Product Title"}
              name="title"
              placeholder={
                productType === "event"
                  ? ""
                  : "Example: Wooden rainbow stacking toy."
              }
              required
              error={touched.title && errors.title}
              autoFocus
            />
          </Grid>
          <Grid item>
            <MoneyInput
              label={
                productType === "standard"
                  ? "Base Price"
                  : productType === "custom"
                  ? "Starting Price"
                  : "Price"
              }
              name="price"
              placeholder="$0.00"
              required
              error={touched.price && errors.price}
              variant="small"
            />
            {productType === "event" && (
              <Typography variant="caption4">
                Additional pricing and event variations can be added later.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NameAndPrice;
