import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useHistory } from "react-router-dom";
import {
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useFeatures, useVariation } from "@castiron/components";
import { getProductsAction } from "../../store/reducers/products";
import { openModal } from "../../store/reducers/modalConductor";
import Stats from "./Stats";
import { setRedirectUrl } from "../../store/reducers/shops";
import Spinner from "../Spinner";
import { LayoutPageProps } from "../Layout";
import AvatarMenu from "../Menus/AvatarMenu";
import SubscriptionBanner from "../SubscriptionBanner";
import ViewShopButton from "../Layout/Header/ViewShopButton";
import { Banner, Button, Typography } from "@castiron/components";
import { ChecklistValues, backendStateToFrontendState } from "@castiron/domain";
import { getProductStatus, useTracking } from "@castiron/utils";
import { shopRepository } from "../../domain";
import firebase from "firebase/compat/app";
import FeatureTooltip from "../FeatureTooltip";
import LaunchIcon from "@material-ui/icons/Launch";
import RedeemCouponBanner from "../RedeemCouponBanner";
import * as helpscout from "../../lib/helpscount";

const useStyles = makeStyles((theme: Theme) => ({
  actionTile: {
    backgroundColor: theme.branding.v2.gray[0],
    borderRadius: 12,
    color: theme.branding.v2.plum[500],
    height: 100,
    padding: 16,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    "&:hover": {
      border: `1px solid ${theme.branding.v2.gray[300]}`,
      cursor: "pointer",
    },
  },
  actionTilesContainer: {
    gap: 8,
  },
  activeStatusPill: {
    backgroundColor: theme.branding.v2.green[50],
    color: theme.branding.v2.green[500],
  },
  arrow: {
    color: theme.branding.v2.plum[500],
    height: 20,
    width: 20,
  },
  arrowContainer: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    marginLeft: 16,
    padding: 10,
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: 17,
    },
  },
  launchIcon: {
    height: 16,
    marginLeft: 8,
    width: 16,
  },
  noSalesBanner: {
    backgroundColor: theme.branding.v2.green[50],
    color: theme.branding.v2.green[500],
  },
  pill: {
    borderRadius: 100,
    padding: "4px 12px",
    width: "fit-content",
  },
  prelaunchStatusPill: {
    backgroundColor: theme.branding.v2.yellow[50],
    color: theme.branding.v2.yellow[900],
  },
  setup: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    padding: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  setupNoCursor: {
    "&:hover": {
      cursor: "auto",
    },
  },
  spinnerContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  toDoTile: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    gap: 8,
    padding: 16,
  },
  tooltip: {
    margin: 0,
  },
}));

export interface Props extends LayoutPageProps {
  popChat?: boolean;
}

const Dashboard: React.FC<Props> = (props) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs, popChat } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showSpinner, setShowSpinner] = useState(false);
  const [isPublishingShop, setIsPublishingShop] = useState(false);

  const subscriptionBannerExperiment = useVariation(
    "Subscription Upgrade Banner Test"
  );
  const isVariant = subscriptionBannerExperiment?.name === "Home Page";
  const features = useFeatures();
  const isQuotesEnabled = features.includes("admin.quotes");

  const {
    discount,
    isReady,
    shop,
    transactions,
    products,
    redirectUrl,
    isProductsLoading,
    isShopLoading,
    modal,
    stripeStatus,
  } = useAppSelector((state) => ({
    account: state.shops.account,
    discount: state.shops.discount,
    isReady: state.shops.account?.isReady,
    shop: state.shops.shop,
    transactions: state.transactions.transactions,
    isTransactionsLoading: state.transactions.loading,
    isProductsLoading: state.products.loading,
    isShopLoading: state.shops.loading,
    products: state.products.products,
    redirectUrl: state.shops.redirectUrl,
    modal: state.modal,
    stripeStatus: state.shops.stripe.status,
  }));

  const shopStatusChecker =
    products?.filter(
      (p) => getProductStatus(p, shop?.config?.timeZone) === "active"
    )?.length > 0
      ? "active"
      : shop.status;

  const openedOrderCount =
    transactions?.filter(
      (tx) => backendStateToFrontendState(tx, "order") === "open"
    )?.length || 0;
  const newQuoteCount =
    transactions?.filter(
      (tx) =>
        tx.order?.type === "custom" &&
        backendStateToFrontendState(tx, "quote") === "new"
    )?.length || 0;

  useEffect(() => {
    if (popChat) {
      helpscout.popChat();
    }
  }, [popChat]);

  const displaySeoLocationModal = () => {
    dispatch(
      openModal({
        modalType: "SEO_LOCATION_MODAL",
        modalProps: {
          show: true,
        },
      })
    );
  };

  useEffect(() => {
    // Check for redirect in query param
    if (shop && redirectUrl && history) {
      history.push(redirectUrl);
      dispatch(setRedirectUrl(""));
    }

    const seoLocationModal = localStorage.getItem("seenSeoLocationModal");
    if (
      !shop?.seoMetadata?.address?.addressLocality &&
      !shop?.seoMetadata?.address?.addressRegion &&
      !seoLocationModal
    ) {
      displaySeoLocationModal();
    }

    setPageTitle("Home");
    setFooterCTAs([]);

    window.scrollTo(0, 0);

    return () => {
      setPageTitle("");
    };
  }, []);

  useEffect(() => {
    setHeaderCTAs([<ViewShopButton />, isMobile && <AvatarMenu />]);
  }, [isMobile]);

  useEffect(() => {
    if (shop && shop.id) {
      dispatch(getProductsAction(shop.id));
    }
    if (isReady && shop?.status === "prelaunch") {
      publishShop();
    }
  }, [dispatch, isReady, shop]);

  const shopStatusPill = {
    active: (
      <Typography
        className={`${classes.pill} ${classes.activeStatusPill}`}
        variant="caption"
      >
        Shop Status: Accepting Orders
      </Typography>
    ),
    prelaunch: (
      <Typography
        className={`${classes.pill} ${classes.prelaunchStatusPill}`}
        variant="caption"
      >
        Shop Status: Not Published
      </Typography>
    ),
  };

  const openShareLinksModal = () => {
    dispatch(
      openModal({
        modalType: "SHARE_LINKS_MODAL",
        modalProps: {
          show: true,
        },
      })
    );
  };

  const publishShop = async () => {
    setIsPublishingShop(true);
    await shopRepository?.updateProps(shop.id, {
      checklistCompletions: firebase.firestore.FieldValue.arrayUnion(
        ChecklistValues.GoLive
      ),
      status: "active",
    });

    setIsPublishingShop(false);

    dispatch(
      openModal({
        modalType: "SHOP_LIVE_MODAL",
        modalProps: {
          show: true,
        },
      })
    );
  };

  const toDoTiles = [
    {
      text: "Open Orders",
      count: openedOrderCount,
      ctaAction: () => {
        trackEvent("To Do Tile Clicked", { type: "Orders" });
        history.push("/orders");
      },
    },
    ...(isQuotesEnabled
      ? [
          {
            text: "New Quotes",
            count: newQuoteCount,
            ctaAction: () => {
              trackEvent("To Do Tile Clicked", { type: "Quotes" });
              history.push("/quotes");
            },
          },
        ]
      : []),
  ];

  const actionTiles = [
    {
      icon: "🛍️",
      text: "Create a New Product",
      ctaAction: () => {
        history.push("/products");
      },
    },
    {
      icon: "📱",
      text: "Market Products with SMS",
      ctaAction: () => {
        history.push("/marketing/sms");
      },
    },
  ];

  const wrapWithFeatureTooltip = (tile, children) => (
    <FeatureTooltip
      key={`feature-${tile.text}`}
      feature={tile.feature}
      featureName={tile.featureName}
    >
      {children}
    </FeatureTooltip>
  );

  const getActionTile = (tile) => {
    const content = (
      <Grid
        key={tile.text}
        container
        item
        xs={12}
        sm={isQuotesEnabled ? 6 : 12}
        alignItems="center"
        direction="column"
        justify="center"
        className={classes.actionTile}
        onClick={() => {
          trackEvent("Home Action Tile Clicked", { type: tile.text });
          if (!tile.useFeatureTooltip) {
            tile.ctaAction();
          }
        }}
      >
        {tile.icon}
        <Typography
          style={{ color: "inherit", textAlign: "center" }}
          variant="subtitle2"
        >
          {tile.text}
        </Typography>
      </Grid>
    );
    if (tile.useFeatureTooltip) {
      return wrapWithFeatureTooltip(tile, content);
    }
    return content;
  };

  const businessStatsSection = (
    <Grid container direction="column" wrap="nowrap" style={{ gap: 8 }}>
      <Typography variant="h3">Business Stats</Typography>
      {transactions?.length === 0 && (
        <Banner className={classes.noSalesBanner} noIcon variant="success">
          <Typography style={{ color: "inherit" }} variant="body2">
            Zero sales, unlimited potential! Let's create your success story!
            🛍️✨
          </Typography>
        </Banner>
      )}
      <Grid container>
        <Stats />
      </Grid>
    </Grid>
  );

  const toDoSection = (
    <Grid container direction="column" wrap="nowrap">
      <Typography style={{ marginBottom: 8 }} variant="h3">
        To Do
      </Typography>
      <Grid container item wrap="nowrap" style={{ gap: 8 }}>
        {toDoTiles.map((tile) => (
          <Grid
            container
            item
            key={tile.text}
            direction="column"
            alignItems="center"
            justify="space-between"
            className={classes.toDoTile}
            wrap="nowrap"
          >
            <Typography variant="subtitle2">{tile.text}</Typography>
            <Typography variant="h2">{tile.count}</Typography>
            <Typography
              variant="subtitle2"
              style={{ color: theme.branding.v2.plum[500], cursor: "pointer" }}
              onClick={tile.ctaAction}
            >
              View All
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  return (
    <>
      {modal.modalType !== "CHANGE_PLANS_MODAL" && (
        <Spinner
          show={isShopLoading || isProductsLoading || showSpinner}
          size={"fullscreen"}
        />
      )}
      <div>
        <Helmet>
          <title>Home | Nourysh</title>
        </Helmet>
        <Grid className={classes.container} container>
          {discount && <RedeemCouponBanner />}
          {isVariant && (
            <Grid xs={12}>
              <SubscriptionBanner />
            </Grid>
          )}
          <Grid
            container
            direction="column"
            alignItems="center"
            wrap="nowrap"
            style={{ gap: isMobile ? 32 : 40, maxWidth: 800, margin: "0 auto" }}
          >
            <Grid
              alignItems="center"
              container
              direction="column"
              item
              style={{ gap: 8 }}
            >
              <Typography variant={isMobile ? "h3" : "h2"}>
                👋🏾 Welcome, {shop.businessName}!
              </Typography>
              {shopStatusPill[shopStatusChecker]}
              {shopStatusChecker === "active" && (
                <Button
                  className={classes.pill}
                  onClick={openShareLinksModal}
                  variant="contained"
                >
                  <Typography style={{ color: "inherit" }} variant="caption">
                    Share My Shop
                    <LaunchIcon className={classes.launchIcon} />
                  </Typography>
                </Button>
              )}
            </Grid>

            {stripeStatus === "READY" ? toDoSection : businessStatsSection}

            {/* action tiles */}
            <Grid container direction="column" wrap="nowrap" style={{ gap: 8 }}>
              <Typography variant="h3">Grow Your Biz</Typography>
              <Banner variant="info-plum" noIcon>
                <Typography
                  variant="body4"
                  style={{ color: theme.branding.v2.plum[500] }}
                >
                  Add more products to increase interactions with your offerings
                  on the Nourysh Marketplace.
                </Typography>
              </Banner>
              <Grid
                container
                item
                wrap="nowrap"
                direction={isMobile ? "column" : "row"}
                className={classes.actionTilesContainer}
              >
                {actionTiles.map((tile) => getActionTile(tile))}
              </Grid>
            </Grid>

            {stripeStatus === "READY" ? businessStatsSection : toDoSection}

            {/* {!isTransactionsLoading && transactions && (
              <Tables ordersData={getOpenOrders(transactions)} productsData={products} />
            )} */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
