import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import InventoryInput from "./FormComponents/InventoryInput";
import ProductStatus from "./FormComponents/ProductStatus";
import CategoryInput from "./FormComponents/CategoryInput";
import { useFormikContext } from "formik";
import {
  Button,
  CheckboxInput,
  ChipSelectInput,
  CollapsableCard,
  CustomSwitch,
  Input,
  PriceInput,
  TextInput,
  Typography,
} from "@castiron/components";
import RichTextInput from "../../RichTextEditor";
import { FeatureFlag } from "@castiron/castiron-firebase";
import {
  BaseProduct,
  fulfillmentTypeDisplayName,
  holidays,
  occasions,
  ProductType,
} from "@castiron/domain";
import { stripHtml } from "@castiron/utils";
import TermsModal from "./FormComponents/TermsModal";
import PhotoSection from "./PhotosSection";
import { getService } from "../../../firebase";
import { useAppDispatch } from "../../../hooks";
import { openModal } from "../../../store/reducers/modalConductor";
import ProductInsightTile from "./FormComponents/ProductInsightTile";
import Tooltip from "../../Tooltip";
import EventDate from "./FormComponents/EventDate";
import EventLocation from "./FormComponents/EventLocation";

type Props = {
  onCancelClick?: () => void;
  categories: any[];
  type: ProductType;
  product?: BaseProduct;
  setIsModalPopped: (isModalPopped: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  allergensContainer: {
    paddingBottom: 16,
    paddingTop: 7,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
      paddingTop: 1,
    },
  },
  categoryLabel: {
    display: "flex",
    justifyContent: "flex-start",
  },
  checkbox: {
    paddingLeft: 0,
  },
  descriptionContainer: {
    marginTop: "16px",
  },
  fulfillmentSwitch: {
    margin: "0 0 8px 0",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  marketplaceLink: {
    textDecoration: "none",
    cursor: "pointer",
    color: theme.branding.v2.plum[500],
  },
  minimumContainer: {
    minHeight: 56,
  },
  specialsContainer: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  specialsCountChip: {
    padding: "4px 12px",
    height: "auto",
    borderRadius: "100px",
    backgroundColor: theme.branding.v2.gray[100],
    "& .MuiChip-label": {
      padding: 0,
    },
  },
  subLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.branding.gray[600],
  },
  visibilityContainer: {
    paddingTop: 24,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 8,
    },
  },
}));

const generateDescriptionService = getService(
  "products",
  "generatedescription"
);

const EditProductForm: React.FC<Props> = (props: Props) => {
  const { categories, type, product, setIsModalPopped } = props;
  const {
    values,
    setFieldValue,
    errors,
    handleChange,
    touched,
    handleBlur,
  }: any = useFormikContext();
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState<boolean>();
  const [hasGenerated, setHasGenerated] = useState<boolean>(false);
  const location = useLocation<{
    fromCreate: boolean;
    generationId: string;
    generatedDescription: string;
  }>();
  const dispatch = useAppDispatch();

  const { generationId } = location?.state || {};

  const totalSpecials = values.holidays.length + values.occasions.length;
  const productTypeName = product?.type === "event" ? "event" : "product";

  useEffect(() => {
    if (generationId) {
      console.log("Generation ID", { generationId });
      setHasGenerated(true);
    }
  }, [location]);

  const classes = useStyles();
  const theme = useTheme();

  const handleCategoryChange = useCallback((category) => {
    setFieldValue("category", category);
  }, []);

  const editorHeight = type === "custom" ? 150 : 250;

  const generateDesc = async () => {
    setShowSpinner(true);
    const response = await generateDescriptionService({
      productId: product.id,
      title: values.title,
      category: values.category?.name,
      allergens: values.allergen?.join(", "),
      dietary: values.dietary?.join(", "),
    });
    setHasGenerated(true);
    setFieldValue("generationId", response.generationId);
    setFieldValue("rawGeneratedDesc", response.description);
    setFieldValue("description", response.description);
    setShowSpinner(false);
  };

  const openAboutModal = () => {
    setIsModalPopped(true);
    dispatch(
      openModal({
        modalType: "ABOUT_CASTIRON_AI_MODAL",
        modalProps: {
          setIsModalPopped,
          type: "product-description",
        },
      })
    );
  };

  const visibility = (
    <CollapsableCard
      contentClassName={classes.visibilityContainer}
      defaultExpanded
      noScroll
      title="Availability"
    >
      <ProductStatus context="product" />
      <CategoryInput
        classes={classes}
        value={values.category}
        categories={categories}
        onChange={handleCategoryChange}
        context={type === "event" ? "events" : undefined}
      />
    </CollapsableCard>
  );

  return (
    <Grid container item style={{ gap: 52 }} wrap="nowrap">
      <Grid container direction="column" item xs={12} md={7} lg={8}>
        <CollapsableCard defaultExpanded noScroll title="Basic Information">
          <TextInput
            error={touched.title && errors.title}
            label="Title"
            name="title"
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue("title", e.target.value);
              if (values?.seo?.useProductTitle) {
                setFieldValue("seo.title", e.target.value.slice(0, 80));
              }
            }}
            placeholder={`Title your ${productTypeName}`}
            required
          />
          <FeatureFlag name="feature_castiron_internal">
            <Box style={{ marginTop: "24px" }}>
              <TextInput label="Seller Id" name="sellerId" />
            </Box>
          </FeatureFlag>
          <Box className={classes.descriptionContainer}>
            <Grid container justify="space-between">
              <Grid alignItems="center" container item xs wrap="nowrap">
                <Typography variant="subtitle2">Description</Typography>
                <Tooltip
                  arrow
                  title={`Some items you may want to include: what makes your ${productTypeName} unique, what a customer needs to provide, date cutoffs, etc.`}
                />
              </Grid>
              <Button
                loading={showSpinner}
                disabled={showSpinner}
                variant="text"
                onClick={generateDesc}
                style={{ padding: 0 }}
              >
                ✨ {hasGenerated ? "Try Writing Again" : "Write with AI"}
              </Button>
            </Grid>
            <RichTextInput
              name="description"
              onChange={(text) => {
                setFieldValue("description", text);
                if (values?.seo?.useProductDescription) {
                  setFieldValue(
                    "seo.description",
                    stripHtml(text).slice(0, 300)
                  );
                }
              }}
              placeholder={
                product?.type === "event"
                  ? "Add your event description..."
                  : `What do you want customers to know about this ${productTypeName} when they are shopping?`
              }
              height={editorHeight}
            />
          </Box>
          {(type === "standard" || type === "event") && (
            <Grid
              container
              item
              direction="column"
              wrap="nowrap"
              style={{ gap: "8px" }}
            >
              <PriceInput
                error={touched.price && errors.price}
                label={type === "event" ? "Price" : "Base Price"}
                name="price"
                onChange={handleChange}
                required
              />
            </Grid>
          )}
          {type === "custom" && (
            <Grid
              alignItems="flex-start"
              container
              style={{ gap: 12 }}
              wrap="nowrap"
            >
              <Grid
                container
                item
                direction="column"
                wrap="nowrap"
                xs={6}
                style={{ gap: "8px" }}
              >
                <PriceInput
                  error={touched.startingPrice && errors.startingPrice}
                  label="Starting Price"
                  name="startingPrice"
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: 18 }}>
                <TextInput
                  className={classes.minimumContainer}
                  label="Minimum"
                  name="minimum"
                  placeholder="e.g. two dozen or $100"
                />
              </Grid>
            </Grid>
          )}
          <Box style={{ marginBottom: 7, marginTop: 24 }}>
            <CheckboxInput
              className={classes.checkbox}
              name="isFeatured"
              label={
                <>
                  <Typography variant="subtitle2">
                    ⭐️ Feature This{" "}
                    {type === "custom"
                      ? "Order Form"
                      : type === "standard"
                      ? "Product"
                      : "Event"}
                  </Typography>
                  <Typography
                    style={{ color: theme.branding.v2.gray[700] }}
                    variant="body2"
                  >
                    Up to 4 featured{" "}
                    {type === "custom"
                      ? "order forms"
                      : type === "standard"
                      ? "products"
                      : "events"}{" "}
                    will be highlighted on your homepage.
                  </Typography>
                </>
              }
              variant="label-right"
            />
          </Box>
          {(type === "standard" || type === "event") && <InventoryInput />}
        </CollapsableCard>
        <Hidden mdUp>
          <ProductInsightTile
            context="product"
            id={product?.id}
            isEvent={product?.type === "event"}
          />
          {visibility}
        </Hidden>
        <CollapsableCard
          contentClassName={classes.specialsContainer}
          noScroll
          defaultExpanded
          title={
            <Grid
              container
              direction="row"
              wrap="nowrap"
              style={{ gap: "4px" }}
            >
              <Typography variant="subtitle1">Holidays & Occasions</Typography>
              {totalSpecials > 0 && (
                <Chip
                  label={
                    <Typography variant="caption">{totalSpecials}</Typography>
                  }
                  className={classes.specialsCountChip}
                />
              )}
            </Grid>
          }
        >
          <Typography variant="body2">
            Help customers find your{" "}
            {product?.type === "event"
              ? "ticketed events and classes"
              : "products"}{" "}
            on the{" "}
            <span
              onClick={() => window.open("https://shopcastiron.com/", "_blank")}
              className={classes.marketplaceLink}
            >
              Nourysh Marketplace
            </span>
            .
          </Typography>
          <ChipSelectInput
            label="Holidays"
            secondaryLabel={`Select all major holidays this ${productTypeName} caters to, such as Halloween.`}
            name="holidays"
            options={holidays}
            value={values.holidays}
          />
          <ChipSelectInput
            label="Occasions"
            secondaryLabel={`Select all ongoing occasions this ${productTypeName} caters to, such as Weddings and Birthdays.`}
            name="occasions"
            options={occasions}
            value={values.occasions}
          />
        </CollapsableCard>
        {product?.type === "event" && (
          <>
            <EventLocation />
            <EventDate />
          </>
        )}
        {type === "custom" && (
          <CollapsableCard noScroll title="Policies">
            <Input
              type="text"
              label={
                <>
                  <Typography className={classes.subLabel}>
                    All custom orders purchased through your shop come with
                    standard{" "}
                    <span
                      className={classes.link}
                      onClick={(e) => setTermsModalOpen(true)}
                    >
                      terms & conditions
                    </span>{" "}
                    to help protect your business. Use policies to give detailed
                    information about how you take and fulfill custom orders for
                    this product.
                  </Typography>
                  <TermsModal
                    open={termsModalOpen}
                    onClose={() => setTermsModalOpen(false)}
                  />
                </>
              }
              name="policies"
              multiline={true}
              rows={5}
              placeholder="What details do customers need to know about your custom product like lead time, cancellations, and design types?"
            />
          </CollapsableCard>
        )}
        <PhotoSection product={product} />
      </Grid>
      <Hidden smDown>
        <Grid container direction="column" item md={5} lg={4}>
          <ProductInsightTile
            context="product"
            id={product?.id}
            isEvent={product?.type === "event"}
          />
          {visibility}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default EditProductForm;
