import React from "react";
import { Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Chip,
  ToggleButton,
  ToggleButtonOption,
  Typography,
} from "@castiron/components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LaunchIcon from "@material-ui/icons/Launch";
import PaymentIcon from "@material-ui/icons/Payment";
import { useFormikContext } from "formik";
import { nanoid } from "nanoid";
import { useAppSelector } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  feeStructureOptionWrapper: {
    position: "relative",
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 16,
    padding: 12,
    cursor: "pointer",
    "&:hover, &:focus": {
      borderColor: theme.branding.v2.plum[500],
    },
  },
  checkIcon: {
    position: "absolute",
    color: theme.branding.v2.plum[500],
    backgroundColor: "#fff",
    right: -13,
    top: -13,
  },
  feesBody: {
    padding: "0 24px",
    margin: "24px 0",
  },
  feesContainer: {
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 16,
  },
  feesDescription: {
    background: theme.branding.gray[200],
    padding: 16,
    borderRadius: 16,
    marginBottom: 15,
  },
  feesDivider: {
    borderTop: `1px solid ${theme.branding.gray[300]}`,
  },
  feesHeader: {
    padding: "0 24px",
    height: 64,
  },
  feeText: {
    marginLeft: 15,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 1,
    },
  },
  launchIcon: {
    color: theme.branding.gray[600],
    marginLeft: 11,
    fontSize: 16,
  },
  toggleWrapper: {
    width: 300,
    marginTop: 24,
    fontWeight: 600,
    lineHeight: "24px",
    "& button": {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "24px",
      width: "50%",
    },
    "& .MuiToggleButtonGroup-root": {
      color: theme.branding.v2.plum[500],
      marginBottom: 8,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
}));

export type FeeType = "customer" | "artisan" | "split";

type Props = {};

const FeeStructure: React.FC<Props> = (props: Props) => {
  const {} = props;
  const { values, setFieldValue }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXsMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { account, shop } = useAppSelector((state) => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const feeStructuresInfo = [
    {
      value: "customer",
      header: "Customers pay 100% of fees",
      subtext: "Customers will be charged at checkout.",
      recommended: true,
    },
    {
      value: "artisan",
      header: "I pay 100% of fees",
      subtext: "Fees will be deducted before payout.",
      recommended: false,
    },
    {
      value: "split",
      header: "We will split fees 50/50",
      subtext:
        "Fees will be split, half charged at checkout & half deducted before payout.",
      recommended: false,
    },
  ];

  const FeeStructureOption = (structure) => {
    const active = values.chosenFeeStructure === structure.value;
    return (
      <Grid
        // onClick={() => setFieldValue("chosenFeeStructure", structure.value)}
        container
        direction="column"
        alignItems="flex-start"
        xs={isMobile ? 12 : 4}
        item
        className={classes.feeStructureOptionWrapper}
        style={
          active
            ? {
                border: `2px solid ${theme.branding.v2.plum[500]}`,
                padding: 11,
              }
            : {}
        }
        key={nanoid()}
      >
        {active && <CheckCircleIcon className={classes.checkIcon} />}
        <Typography variant="subtitle2">{structure.header}</Typography>
        <Typography variant="body2">{structure.subtext}</Typography>
        {structure.recommended && (
          <Chip
            style={{ background: theme.branding.blue.light, marginTop: 10 }}
            uppercase
            bold
          >
            RECOMMENDED
          </Chip>
        )}
      </Grid>
    );
  };

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  return (
    <Grid container className={classes.feesContainer}>
      <Grid
        container
        className={classes.feesHeader}
        justify="space-between"
        alignItems="center"
        xs={12}
      >
        <Grid item>
          <Typography variant="subtitle1">Fees</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.feesDivider} xs={12}>
        <Grid container className={classes.feesBody} xs={12}>
          <Grid className={classes.feesDescription} xs={12}>
            <Grid container wrap="wrap" alignItems="center" xs={12}>
              <PaymentIcon />
              <Typography variant="subtitle1" className={classes.feeText}>
                How are fees calculated?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <ul style={{ paddingLeft: 16, paddingTop: 8 }}>
                <li>
                  <Typography variant="body2">
                    The Nourysh transaction fee (4%) is calculated on the order
                    total before any taxes, tips, or additional fees are
                    applied. The transaction fee never applies to any tips
                    received.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    The standard payment processing fee (2.9% + $0.30) goes
                    directly to Stripe for processing the payment and is
                    calculated on total transaction amount.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FeeStructure;
