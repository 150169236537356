import {
  BusinessAddressInput,
  CollapsableCard,
  TextInput,
  ToggleButton,
  ToggleButtonOption,
  Typography,
} from '@castiron/components';
import { FormControl, Grid, Theme, makeStyles, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    gap: 8,
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
  },
}));

const EventLocation: React.FC<Props> = (props: Props) => {
  const {} = props;

  const classes = useStyles();
  const theme = useTheme();

  const { errors, values, setFieldValue }: any = useFormikContext();

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'In Person',
    },
    {
      value: false,
      label: 'Virtual',
    },
  ];

  return (
    <CollapsableCard defaultExpanded noScroll title="Location">
      <FormControl variant="outlined" style={{ width: '100%', margin: 0 }}>
        <Grid container item direction="column" style={{ gap: '24px' }}>
          <Grid container item direction="column" className={classes.toggleButton}>
            <Typography variant="subtitle2">
              Event Location <span style={{ color: theme.branding.v2.red[500] }}>*</span>
            </Typography>
            <ToggleButton
              value={values?.eventDetails?.location?.inPerson}
              exclusive
              onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
                setFieldValue('eventDetails.location.inPerson', value);
              }}
              aria-label="event location"
              buttonOptions={toggleButtonOptions}
            />
          </Grid>
          {values?.eventDetails?.location?.inPerson ? (
            <>
              <TextInput label="Venue Name" name="eventDetails.location.name" />
              <BusinessAddressInput label="Venue Address" name="eventDetails.location.address" removeBusinessInfo customColor={theme.branding.v2.plum[500]} />
            </>
          ) : (
            <Grid container item direction="column">
              <TextInput
                label="URL to Join Event"
                name="eventDetails.location.meetingUrl"
                required
                error={errors?.eventDetails?.location?.meetingUrl}
              />
              <Typography variant="caption4" style={{ marginTop: 4 }}>
                This will only be shared with paid ticket holders after purchase.
              </Typography>
            </Grid>
          )}
          <Grid container item direction="column">
            <TextInput label="Notes" name="eventDetails.location.notes" multiline rows={4} />
            <Typography variant="caption4" style={{ margin: '4px 0px 8px 0px' }}>
              {values?.eventDetails?.location?.inPerson
                ? 'Add parking notes, location navigation notes, or any other details customers might need.'
                : 'Add joining information, recording notes, or other details customers might need.'}
            </Typography>
            <Typography variant="caption4">
              These notes will be shared in order confirmation and event reminder emails.
            </Typography>
          </Grid>
        </Grid>
      </FormControl>
    </CollapsableCard>
  );
};

export default EventLocation;
